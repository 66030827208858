import HTTP from '../utils/HTTP';

class DashboardService {
	fetchDashboardCounts = () => {
		return HTTP.get('/admin/dashboard')
	};
	
}

export default new DashboardService();

import HTTP from '../utils/HTTP';

class ExpencesService {
	fetchExpencesKid = (kid_id) => {
		return HTTP.get('/admin/expenditure',{
			params:{
				kid_id
			}
		})
	};

	fetchBasicExpences = () => {
		return HTTP.get('/admin/expenditure/basic')
	};
	addBasicExpences = (data) => {
		return HTTP.post('/admin/expenditure/basic/add', data)
	};
	updateBasicExpences = (data) => {
		return HTTP.put(`/admin/expenditure/basic/update`, data)
	};
	deleteBasicExpences = (id) => {
		return HTTP.delete(`/admin/expenditure/basic/delete/${id}`)
	};


    fetchCurriculumExpences = () => {
		return HTTP.get('/admin/expenditure/curriculum')
	};
	addCurriculumExpences = (data) => {
		return HTTP.post('/admin/expenditure/curriculum/add', data)
	};
	updateCurriculumExpences = (data) => {
		return HTTP.put(`/admin/expenditure/curriculum/update`, data)
	};
	deleteCurriculumExpences = ({id, amount, kid_id}) => {
		return HTTP.delete(`/admin/expenditure/curriculum/delete`,{
			params:{
				id,
				amount,
				kid_id
			}
		})
	};

    fetchExtraCurriculumExpences = () => {
		return HTTP.get('/admin/expenditure/extra-curriculum')
	};
	addExtraCurriculumExpences = (data) => {
		return HTTP.post('/admin/expenditure/extra-curriculum/add', data)
	};
	updateExtraCurriculumExpences = (data) => {
		return HTTP.put(`/admin/expenditure/extra-curriculum/update`, data)
	};
	deleteExtraCurriculumExpences = ({id, amount, kid_id}) => {
		return HTTP.delete(`/admin/expenditure/extra-curriculum/delete`,{
			params:{
				id,
				amount,
				kid_id
			}
		})
	};

    fetchEducationExpences = () => {
		return HTTP.get('/admin/expenditure/education')
	};
	addEducationExpences = (data) => {
		return HTTP.post('/admin/expenditure/education/add', data)
	};
	updateEducationExpences = (data) => {
		return HTTP.put(`/admin/expenditure/education/update`, data)
	};
	deleteEducationExpences = ({id,amount, kid_id}) => {
		return HTTP.delete(`/admin/expenditure/education/delete`,{
			params:{
				id,
				amount,
				kid_id
			}
		})
	};

	
}

export default new ExpencesService();

import HTTP from '../utils/HTTP';

class PaymentService {
	fetchPayments = () => {
		return HTTP.get('/payment')
	};
	fetchLatestPayments = () => {
		return HTTP.get('/payment/latest')
	};

	addPayments = (data) => {
		return HTTP.post('/payment/add',data)
	};

	deletePayment = (id) => {
		return HTTP.delete(`/payment/delete/${id}`)
	};
}

export default new PaymentService();

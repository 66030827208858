import * as React from "react";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  Button,
  Typography,
  Modal,
  TextField,
  FormHelperText,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import "react-country-state-city/dist/react-country-state-city.css";
import PersonOutlineIcon from "@mui/icons-material/Person2Rounded";
import convertBase64 from "../../../utils/convertBase64";
import { uploadImgMaxSize } from "../../../utils/uploadImg";
import Toast from "../../../components/toast/Toast";

import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  full_name: yup.string("Enter fullname").required("Fullname is required"),
  mobile_no: yup
    .string("Enter Mobile number")
    .required("Mobile number is required"),
  email: yup.string("Enter email").email().required("Email is required"),
  role: yup.string("Enter role").required("Role is required"),
  gender: yup.string("Select gender").required("Gender is required"),
  password: yup.string("Enter Password").required("Password is required"),
});

export default function UserModal({
  open,
  onClose,
  onSubmit,
  selectedRow,
  setFile,
  onDeleteAvtar
}) {
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [avtarImg, setAvtarImg] = useState();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");

  const formik = useFormik({
    initialValues: {
      full_name: selectedRow.full_name || "",
      password: selectedRow.password || "",
      mobile_no: selectedRow.mobile_no || "",
      email: selectedRow.email || "",
      role: selectedRow.role || "",
      gender: selectedRow.gender || "",
      avatar_url: selectedRow.avatar_url || "",
      pan_no: selectedRow.pan_no || "",
      addhar_no: selectedRow.addhar_no || "",
      address: selectedRow.address || "",
      country: selectedRow.country || "",
      state: selectedRow.state || "",
      city: selectedRow.city || "",
      pincode: selectedRow.pincode || "",
      date_of_birth: selectedRow.date_of_birth || new Date(),
      nationality: selectedRow.nationality || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedRow.id) {
        values.id = selectedRow.id;
      }
      values.country = countryId;
      values.state = stateId;
      values.city = cityId;
      console.log(values);
      onSubmit(values, avtarImg);
    },
  });

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  useEffect(() => {
    if (selectedRow.country) {
      setCountryId(selectedRow.country);
      setStateId(selectedRow.state);
      setCityId(selectedRow.city);
      GetState(parseInt(selectedRow.country)).then((result) => {
        setStateList(result);
      });
      GetCity(parseInt(selectedRow.country), parseInt(selectedRow.state)).then((result) => {
        setCityList(result);
      });
    }
  }, [selectedRow]);

  const onImgChange = async (file) => {
    let fileMaxSize = uploadImgMaxSize(file,300);
    if(!fileMaxSize){
        setToastOpen(true);
        setToastStatus("error");
        setToastMsg("Max file size " + 300 + "KB allowed.");
        setFile("");
        setAvtarImg("");
    }
    else{
      let base64Url = await convertBase64(file);
      setAvtarImg("");
      setAvtarImg(base64Url);
      setFile(file);
      console.log(base64Url);
    }
  };
  const onDeleteAvtarHandler = (row) => {
    setFile("");
    setAvtarImg("");
    onDeleteAvtar(row);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-lg">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.id ? "Update" : "Add"} User
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <div className="profile-img">
                    <input
                      style={{ display: "none" }}
                      id="avtarImgId"
                      type="file"
                      onChange={(e) => onImgChange(e.target.files[0])}
                    />
                    {avtarImg || selectedRow.avatar_url ? (
                      <img src={avtarImg || selectedRow.avatar_url} />
                    ) : (
                      <PersonOutlineIcon />
                    )}
                  </div>
                  {selectedRow.avatar_url ? (
                    <label
                      className="upload-btn"
                      onClick={() => onDeleteAvtarHandler(selectedRow)}
                      variant="outlined"
                      fullWidth
                    >
                      Delete Avtar
                    </label>
                  ) : (
                    <label
                      className="upload-btn"
                      htmlFor="avtarImgId"
                      onClick={() => setFile(avtarImg)}
                      variant="outlined"
                      fullWidth
                    >
                      Choose Avtar
                    </label>
                  )}
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="full_name"
                        name="full_name"
                        label="Fullname"
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.full_name &&
                          Boolean(formik.errors.full_name)
                        }
                        helperText={
                          formik.touched.full_name && formik.errors.full_name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        disabled={selectedRow?.email ? true : false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          name="gender"
                          label="Select Gender"
                          defaultValue=""
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={
                            formik.touched.gender &&
                            Boolean(formik.errors.gender)
                          }
                          helperText={
                            formik.touched.gender && formik.errors.gender
                          }
                        >
                          <MenuItem value={""}>Select Gender</MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="mobile_no"
                        name="mobile_no"
                        label="Mobile No"
                        size="small"
                        value={formik.values.mobile_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={
                          formik.touched.mobile_no &&
                          Boolean(formik.errors.mobile_no)
                        }
                        helperText={
                          formik.touched.mobile_no && formik.errors.mobile_no
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="pan_no"
                        name="pan_no"
                        label="Pan Number"
                        value={formik.values.pan_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.pan_no && Boolean(formik.errors.pan_no)
                        }
                        helperText={
                          formik.touched.pan_no && formik.errors.pan_no
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="addhar_no"
                        name="addhar_no"
                        label="Addhar Number"
                        value={formik.values.addhar_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.addhar_no &&
                          Boolean(formik.errors.addhar_no)
                        }
                        helperText={
                          formik.touched.addhar_no && formik.errors.addhar_no
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          name="role"
                          label="Select Role"
                          disabled={selectedRow.role ? true : false}
                          value={formik.values.role}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={
                            formik.touched.role && Boolean(formik.errors.role)
                          }
                          helperText={formik.touched.role && formik.errors.role}
                        >
                          <MenuItem value={""}>Select Role</MenuItem>
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                          <MenuItem value={"superAdmin"}>Super Admin</MenuItem>
                          <MenuItem value={"Mentor"}>Mentor</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          name="nationality"
                          label="Select Nationality"
                          defaultValue=""
                          value={formik.values.nationality}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          margin="dense"
                          size="small"
                          fullWidth
                        >
                          <MenuItem value={""}>Select Nationality</MenuItem>
                          <MenuItem value={"Indian"}>Indians</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        native
                        select
                        label="Select Country"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          setCountryId(e.target.value);
                          GetState(+e.target.value).then((result) => {
                            setStateList(result);
                          });
                        }}
                        value={countryId}
                      >
                        {countriesList?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        native
                        select
                        label="Select State"
                        fullWidth
                        size="small"
                        value={stateId}
                        onChange={(e) => {
                          setStateId(e.target.value);
                          console.log(countryId, e.target.value, "cccc");

                          GetCity(+countryId, +e.target.value).then((result) => {
                            setCityList(result);
                            console.log(result, "rrrrr");
                          });
                        }}
                      >
                        {stateList?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          native
                          select
                          label="Select City"
                          size="small"
                          fullWidth
                          value={cityId}
                          onChange={(e) => {
                            setCityId(+e.target.value);
                          }}
                        >
                          {cityList?.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="pincode"
                        name="pincode"
                        label="pincode"
                        value={formik.values.pincode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.pincode &&
                          Boolean(formik.errors.pincode)
                        }
                        helperText={
                          formik.touched.pincode && formik.errors.pincode
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        label="Address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        multiline
                        rows={2}
                        size="small"
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="text-right">
                <Button
                  color="primary"
                  sx={{ mr: 1, mt: 1 }}
                  variant="contained"
                  type="submit"
                >
                  {selectedRow.id ? "Update" : "Add"}
                </Button>
                <Button onClick={onClose} sx={{ mt: 1 }} variant="outlined">
                  Cancel
                </Button>
              </div>
            </form>
          </Typography>
          {toastOpen && (
            <Toast
              toastOpen={toastOpen}
              toastClose={() => setToastOpen(false)}
              toastMsg={toastMsg}
              toastStatus={toastStatus}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

import { Chip } from "@mui/material";
import moment from "moment/moment";

const columnsData = [
    {
      field: "name",
      headerName: "Kid Name",
      flex: 1,
      
    },
    {
      field: "full_name",
      headerName: "Montor Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
          {!params.row.mentor_id ? 'Anonymous': params.row.full_name}
          </>
        );
      },
    },
    {
        field: "amount",
        headerName: "Amount",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
               ₹{params.row.amount}
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
            return (
              <div>
                  <Chip label={params.row.status} variant="outlined" color={params.row.status === "Paid" ? "success" : "error"}/>
              </div>
            );
          },
       
      },
      {
        field: "payment_date",
        headerName: "Payment Date",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
               {moment(params.row.payment_date).format("DD-MM-YYYY")}
            </div>
          );
        },
      },
  ];
  
  let actionHeader = (handleDelete, setOpen, setSelectedRow) => {
    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        flex: 1,
      },
    ];
    return actionColumn;
  };
  
  const columnHeader = {
    columnsData,
    actionHeader,
  };
  
  export default columnHeader;
  
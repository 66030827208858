import HTTP from '../utils/HTTP';

class NotificationService {
	fetchNotifications = () => {
		return HTTP.get('/notification')
	};
	deleteNotification = (id) => {
		return HTTP.delete(`/notification/delete/${id}`)
	};

}

export default new NotificationService();

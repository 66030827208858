// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import NotificationService from "../../services/notification";
import { useEffect, useState } from "react";
import columnsHeader from "./ColumnsHeaderData";
import Loader from "../../components/spinner/Spinner";

const Notification = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);

  useEffect(() => {
    getAllNotications();
  }, []);

  const getAllNotications = async () => {
    setLoading(true);
    try {
      let { data } = await NotificationService.fetchNotifications();
      setRowData(data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setRowData([]);
    }
  };


  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar}/>
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
            <Datatable
            pageTitle={"Notifications"}
            columnsData={columnsHeader.columnsData}
            actionColumn={columnsHeader.actionHeader(
            )}
            rowData={rowData}
          />
        )}
      </div>
    </div>
  );
};

export default Notification;

import PersonOutlineIcon from "@mui/icons-material/Person2Rounded";

let columnsData = [
  {
    field: "full_name",
    headerName: "Fullname",
    flex: 1,
    minWidth:150,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row?.avatar_url ? (
            <img
              className="cellImg"
              src={params.row?.avatar_url}
              alt="avatar"
            />
          ) : (
            <PersonOutlineIcon style={{ marginRight: 12, marginLeft: 4 }}/>
          )}

          {params.row.full_name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth:150,
  },
  {
    field: "mobile_no",
    headerName: "Mobile",
    flex: 1,
  },
  {
    field: "gender",
    headerName: "Gender",
    flex: 1,
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1,
    
  },
  {
    field: "password",
    headerName: "Password",
    flex: 1,
  },
];

let actionHeader = (handleDelete, setOpen, setSelectedRow) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth:150,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              onClick={() => {
                setOpen(true);
                setSelectedRow(params.row);
              }}
              className="editButton"
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return actionColumn;
};

const columnHeader = {
  columnsData,
  actionHeader,
};

export default columnHeader;

import moment from "moment/moment";
import { Chip } from "@mui/material";

const columnsData = [
  {
    field: "transaction_id",
    headerName: "Transaction id",
    flex: 1,
    renderCell: (params) => {
      return <div>{params.row.transaction_id}</div>;
    },
  },
  {
    field: "name",
    headerName: "Kid Name",
    flex: 1,
  },
  {
    field: "full_name",
    headerName: "Montor Name",
    flex: 1,
    renderCell: (params) => {
      return (
        <>
          {!params.row.mentor_id && !params.row.mentor_name
            ? "Anonymous"
            : params.row.full_name || params.row.mentor_name}
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    renderCell: (params) => {
      return <div>₹{params.row.amount}</div>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Chip label={params.row.status} variant="outlined" color="success" />
        </div>
      );
    },
  },

  {
    field: "payment_date",
    headerName: "Payment Date",
    flex: 1,
    renderCell: (params) => {
      return <div>{moment(params.row.payment_date).format("DD-MM-YYYY")}</div>;
    },
  },
];

let actionHeader = (handleDelete, onHistoy, histoyMentors ) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {params.row.certificate_url && (
              <div>
                <a
                  target="_blank"
                  style={{
                    border: "1px solid #fdc513",
                    color: "#fdc513",
                    borderRadius: 5,
                    textDecoration: "none",
                    padding: "2px 5px",
                    cursor: "pointer",
                  }}
                  href={params.row.certificate_url}
                  download
                >
                  View Receipt
                </a>
              </div>
            )}

            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
            {histoyMentors.some(s=> params.row.email == s.email) && (
              <div
              className="editButton"
              onClick={() => onHistoy(params.row.email)}
            >
              Histoy
            </div>)}
          </div>
        );
      },
    },
  ];
  return actionColumn;
};

const columnHeader = {
  columnsData,
  actionHeader,
};

export default columnHeader;

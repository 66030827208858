// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import SchoolService from "../../services/school";
import { useEffect, useState } from "react";
import SchoolModal from "./components/SchoolModal";
import Toast from "../../components/toast/Toast";
import columnsHeader from "./ColumnsHeaderData";
import Loader from "../../components/spinner/Spinner";
import Backdrop from "../../components/backdrop/Backdrop";

const School = () => {
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);

  const handleDelete = async (id) => {
    setBackdrop(true);
    try {
      await SchoolService.deleteSchool(id);
      setToastOpen(true);
      setToastMsg("School deleted successfully");
      setToastStatus("success");
      setOpen(false);
      setBackdrop(false);
      getAllSchools();
    } catch (err) {
      console.log(err);
      setBackdrop(false);
    }
  };

  useEffect(() => {
    getAllSchools();
  }, []);

  const getAllSchools = async () => {
    setLoading(true);
    try {
      let { data } = await SchoolService.fetchSchools();
      setRowData(data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSubmit = async (values) => {
    setBackdrop(true);
    try {
      if (values.id) {
        await SchoolService.updateSchool(values);
      } else {
        await SchoolService.addSchool(values);
      }
      setToastOpen(true);
      setToastMsg(
        `School ${values?.id ? "updated" : "created"} successfully`
      );
      setToastStatus("success");
      setOpen(false);
      getAllSchools();
      setBackdrop(false);
      setSelectedRow({});
    } catch (err) {
      console.log(err);
      setToastOpen(true);
      setBackdrop(false);
      setToastStatus("error");
      setToastMsg("Something went wrong!");
    }
  };

  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar}/>
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
        <Datatable
          pageTitle={"Schools"}
          columnsData={columnsHeader.columnsData}
          actionColumn={columnsHeader.actionHeader(
            handleDelete,
            setOpen,
            setSelectedRow
          )}
          rowData={rowData}
          addBtnTxt={"Add School"}
          onAddBtn={() => {
            setOpen(true);
            setSelectedRow({});
          }}
        />)}
        {open && (
          <SchoolModal
            onSubmit={onSubmit}
            selectedRow={selectedRow}
            open={open}
            onClose={() => setOpen(false)}
          />
        )}
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
       {backdrop && <Backdrop/>}
    </div>
  );
};

export default School;

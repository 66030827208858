import HTTP from '../utils/HTTP';

class SponsoredService {
	fetchSponsor = () => {
		return HTTP.get('/admin/sponsor')
	};
	deleteSponsor = ({id,kid_id}) => {
		return HTTP.delete(`/admin/sponsor/delete`,{
			params:{
				id,
				kid_id
			}
		})
	};
}

export default new SponsoredService();

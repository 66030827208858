import HTTP from '../utils/HTTP';

class SchoolsService {
	fetchSchools = () => {
		return HTTP.get('/admin/school')
	};
	addSchool = (data) => {
		return HTTP.post('/admin/school/add', data)
	};
	updateSchool = (data) => {
		return HTTP.put(`/admin/school/update`, data)
	};
	deleteSchool = (id) => {
		return HTTP.delete(`/admin/school/delete/${id}`)
	};
	
}

export default new SchoolsService();

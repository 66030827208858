import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";


const Datatable = ({columnsData, rowData, pageTitle, actionColumn, addBtnTxt, onAddBtn }) => {
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {pageTitle}
        {addBtnTxt && ( <Button onClick={onAddBtn} size="small" variant="outlined">
          {addBtnTxt} {rowData.id}
        </Button>)}
       
      </div>
    
      <DataGrid
        className="datagrid"
        rows={rowData}
        columns={columnsData.concat(actionColumn)}
        checkboxSelection={false}
        disableColumnSelector
        resizable={true}
      />
    </div>
  );
};

export default Datatable;

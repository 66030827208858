import convertBase64 from "./convertBase64";
import imgUploadService from "../services/uploadImg";

export const uploadImg = async (file, folder) => {
  // const file = e.target.files[0];
  const base64 = await convertBase64(file);
  try {
    const result = await imgUploadService.uploadImg({ image: base64, folder });
    try {
      console.log(result);
      return result.data;
    } catch (err) {
      ///toast.error(err.response.data.message  || "Something went wrong!");
    }
  } catch (err) {
    //toast.error(err.response.data.message  || "Something went wrong!");
  }
};

export const deleteImg = async (publicImgId, folder) => {
  try {
    const result = await imgUploadService.deleteImg(publicImgId, folder);
    console.log(result);
    return result.data;
  } catch (err) {
    //toast.error(err.response.data.message  || "Something went wrong!");
  }
};

export const uploadImgMaxSize = (file, maxFileSize) => {
  let size = parseFloat(file.size);
  let maxSizeKB = maxFileSize; //Size in KB.
  let maxSize = maxSizeKB * 1024; //File size is returned in Bytes.
      if (size > maxSize) {
        return false;
      }
      return true;
};



// import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Category from "./pages/categories/Category";

import Kids from "./pages/kids/Kids";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Users from "./pages/users/Users";
import CurriculumExpences from "./pages/expenditure/co-curriculum/Curriculum";
import EducationExpences from "./pages/expenditure/education/Education";
import ExtraCurriculumExpences from "./pages/expenditure/extra-curriculam/ExtraCurriculam";
import School from "./pages/school/School";
import KidsPhotos from "./pages/kids/KidsPhotos";
import Payment from "./pages/payment/Payment";
import Sponsored from "./pages/sponsored/Sponsored";
import './style/dark.css';
import './style/style.css';
import './style/list.css';
import PrivateRoute from "./utils/privateRoute";
import KidsDocuments from "./pages/kids/KidsDocuments.";
import Notification from "./pages/notification/Notification";
import Certificate from "./pages/certificate/certificate";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const token = localStorage.getItem('token');

  return (
  <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
            <Route path="/" exact  element={<Login/>} />
            <Route path="/dashboard" exact  element={<PrivateRoute><Home /></PrivateRoute>} />  
            <Route path="/category" exact element={<PrivateRoute><Category /></PrivateRoute>} />
            <Route path="/kids" exact element={<PrivateRoute><Kids /></PrivateRoute>} />
            <Route path="/school" exact element={<PrivateRoute><School /></PrivateRoute>} />
            <Route path="/users" exact element={<PrivateRoute><Users /></PrivateRoute>} />
            <Route path="/expenditure/curriculum" exact element={<PrivateRoute><CurriculumExpences /></PrivateRoute>} />
            <Route path="/expenditure/extra-curriculum" exact element={<PrivateRoute><ExtraCurriculumExpences /></PrivateRoute>} />
            <Route path="/expenditure/education" exact element={<PrivateRoute><EducationExpences /></PrivateRoute>} />
            {/* <Route path="/kids/photos" exact element={<PrivateRoute><KidsPhotos /></PrivateRoute>} />
            <Route path="/kids/documents" exact element={<PrivateRoute><KidsDocuments /></PrivateRoute>} /> */}
            <Route path="/payments" exact element={<PrivateRoute><Payment /></PrivateRoute>} />
            <Route path="/certificate" exact element={<PrivateRoute><Certificate /></PrivateRoute>} />
            <Route path="/sponsered" exact element={<PrivateRoute><Sponsored /></PrivateRoute>} />
            <Route path="/notification" exact element={<PrivateRoute><Notification /></PrivateRoute>} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import UserService from "../../services/user";

import { useEffect, useState } from "react";
import Toast from "../../components/toast/Toast";
import UserModal from "./components/UserModal";
import columnsHeader from "./ColumnsHeaderData";

import Loader from "../../components/spinner/Spinner";
import { uploadImg, deleteImg } from "../../utils/uploadImg";
import Backdrop from "../../components/backdrop/Backdrop";
import { Tab, Box, Typography, Tabs } from "@mui/material";

function CustomTabPanel(dataItems) {
  const { children, value, index, ...other } = dataItems;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Users = () => {
  const [rowAdmin, setRowAdmin] = useState([]);
  const [rowMentor, setRowMentor] = useState([]);
  const [rowSuperAdmin, setRowSuperAdmin] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleDelete = async ({ role, id }) => {
    setBackdrop(true);
    try {
      await UserService.deleteUser({ role, id });
      setToastOpen(true);
      setToastMsg(`${role} deleted successfully`);
      setToastStatus("success");
      getAdmin();
      getMentor();
      getSuperAdmin();
      setOpen(false);
      setBackdrop(false);
    } catch (err) {
      setToastOpen(true);
      setToastMsg("Something went wrong!");
      setToastStatus("error");
      console.log(err);
      setBackdrop(false);
    }
  };

  const onSubmit = async (values, avtarImg) => {
    setBackdrop(true);
    try {
      if (avtarImg) {
        const url = await uploadImg(file, "users");
        console.log(url, "url");
        values.avatar_url = url;
      }
      if (values.id) {
        await UserService.updateUser(values);
      } else {
        await UserService.register(values);
      }
      setToastOpen(true);
      setToastMsg(
        `Category ${values?.id ? "updated" : "created"} successfully`
      );
      setToastStatus("success");
      setOpen(false);
      setSelectedRow({});
      getAdmin();
      getMentor();
      getSuperAdmin();
      setBackdrop(false);
    } catch (err) {
      console.log(err);
      setToastOpen(true);
      setBackdrop(false);
      setToastStatus("error");
      setToastMsg(err.response.data.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getAdmin();
    getMentor();
    getSuperAdmin();
  }, []);

  const getAdmin = async () => {
    setLoading(true);
    try {
      let { data } = await UserService.fetchAdmin();
      setRowAdmin(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const getSuperAdmin = async () => {
    setLoading(true);
    try {
      let { data } = await UserService.fetchSuperAdmin();
      setRowSuperAdmin(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getMentor = async () => {
    setLoading(true);
    try {
      let { data } = await UserService.fetchMentor();
      setRowMentor(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onImgDelete = async (row) => {
    let { avatar_url } = row;
    if (avatar_url) {
      let splitPublicId = avatar_url?.split("/").pop();
      let publicId = splitPublicId.split(".")[0];
      console.log(publicId);
      setBackdrop(true);
      try {
        let result = await deleteImg(publicId, "users");
        if (result) {
          row.avatar_url = "";
          const { status } = await UserService.updateUser(row);
          if (status) {
            setBackdrop(false);
            getAdmin();
            getMentor();
            getSuperAdmin();
            setToastMsg("User photo deleted successfully");
            setToastStatus("success");
          }
        }
      } catch (err) {
        setBackdrop(false);
        setToastMsg("Someting went wrong!");
        setToastStatus("error");
      }
    }
  };

  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar} />
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Admin" {...a11yProps(0)} />
                <Tab label="Super Admin" {...a11yProps(1)} />
                <Tab label="Mentor" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Datatable
                pageTitle={"Admin Users"}
                columnsData={columnsHeader.columnsData}
                actionColumn={columnsHeader.actionHeader(
                  handleDelete,
                  setOpen,
                  setSelectedRow
                )}
                rowData={rowAdmin}
                addBtnTxt={"Add Admin"}
                onAddBtn={() => {
                  setOpen(true);
                  setSelectedRow({});
                }}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Datatable
                pageTitle={"Super Admin"}
                columnsData={columnsHeader.columnsData}
                actionColumn={columnsHeader.actionHeader(
                  handleDelete,
                  setOpen,
                  setSelectedRow
                )}
                rowData={rowSuperAdmin}
                addBtnTxt={"Super Admin"}
                onAddBtn={() => {
                  setOpen(true);
                  setSelectedRow({});
                }}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Datatable
                pageTitle={"Mentor Users"}
                columnsData={columnsHeader.columnsData}
                actionColumn={columnsHeader.actionHeader(
                  handleDelete,
                  setOpen,
                  setSelectedRow
                )}
                rowData={rowMentor}
                addBtnTxt={"Add Mentor"}
                onAddBtn={() => {
                  setOpen(true);
                  setSelectedRow({});
                }}
              />
            </CustomTabPanel>
          </>
        )}
        {open && (
          <UserModal
            onSubmit={onSubmit}
            selectedRow={selectedRow}
            open={open}
            onClose={() => setOpen(false)}
            setFile={setFile}
            onDeleteAvtar={onImgDelete}
          />
        )}
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop />}
    </div>
  );
};

export default Users;

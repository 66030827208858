
let columnsData = [
    {
      field: "school_name",
      headerName: "School Name",
      flex: 1,
    },
    {
      field: "school_id",
      headerName: "School Id",
      flex: 1,
    },
    {
      field: "school_address",
      headerName: "Address",
      flex: 1,
    },
  ];
  
  let actionHeader = (handleDelete, setOpen, setSelectedRow) => {
    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          minWidth:150,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                <div
                  onClick={() => {
                    setOpen(true);
                    setSelectedRow(params.row);
                  }}
                  className="editButton"
                >
                  Edit
                </div>
                <div
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </div>
              </div>
            );
          },
        },
      ];
    return actionColumn;
  };
  
  const columnHeader = {
    columnsData,
    actionHeader,
  };
  
  export default columnHeader;
  
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import DashboardService from "../../services/dashboard";
import PaymentService from "../../services/payment";

import { useState, useEffect } from "react";
import columnsHeader from "./ColumnHeaderData";
import Datatable from "../../components/datatable/Datatable";

const Home = () => {
  const [dashboard, setDashboard] = useState([]);
  const [payments, setPayments] = useState([]);
  const [isSidebar, setIsSidebar] = useState(null);

  useEffect(() => {
    getAllDashboard();
    getAllLatestPayments();
    console.log("qqqqqq", dashboard);
  }, []);

  const getAllDashboard = async () => {
    // setLoading(true);
    try {
      let { data } = await DashboardService.fetchDashboardCounts();
      setDashboard(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getAllLatestPayments = async () => {
    // setLoading(true);
    try {
      let { data } = await PaymentService.fetchLatestPayments();
      setPayments(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="home">
      <Sidebar isSidebar={isSidebar} />
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        <div className="widgets">
          {dashboard?.length > 0 && dashboard.map((d) => <Widget data={d} />)}
        </div>
        {/* <div className="charts">
          <Featured />
          <Chart
            paymentData={payments}
            title="Months By (Revenue)"
            aspect={2 / 1}
          />
        </div> */}
        <div className="listContainer">
          <Datatable
            pageTitle={"Latest Transactions"}
            columnsData={columnsHeader.columnsData}
            rowData={payments}
            actionColumn={[]}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Paper,
  Chip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, useFormik } from "formik";
import Loader from "../../../components/spinner/Spinner";
import Backdrop from "../../../components/backdrop/Backdrop";
import Toast from "../../../components/toast/Toast";
import KidsService from "../../../services/kids";
import { uploadImg, deleteImg, uploadImgMaxSize } from "../../../utils/uploadImg";
import PhotoCard from "./PhotoCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height:"80vh",
  p: 4,
};


export default function PhotosModal({ open, onClose, selectedRow }) {
    const [rowData, setRowData] = useState([]);
    const [kids, setKids] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastStatus, setToastStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState();
    const [backdrop, setBackdrop] = useState(false);
  
    const formik = useFormik({
      initialValues: {
        enrollment_id: "",
        photo_url: "",
      },
      onSubmit: async (values) => {
        setBackdrop(true);
        try {
          if (file){
          const url = await uploadImg(file, "kids");
          console.log(url, "url");
          values.photo_url = url;
          values.enrollment_id = selectedRow.enrollment_id;
          if (values.id && url) {
            await KidsService.updateKidPhoto(values);
          } else if(url) {
            await KidsService.addKidPhoto(values);
          }
          setToastOpen(true);
          setToastMsg(
            `Kid Photo ${values?.id ? "updated" : "added"} successfully`
          );
          setToastStatus("success");
          getAllKidsPhotos();
          setBackdrop(false);
          setFile("");
          document.getElementById("photoUrl").value = "";
          formik.resetForm();
        }
        } catch (err) {
          console.log(err);
          setBackdrop(false);
          setToastOpen(true);
          setToastStatus("error");
          setToastMsg("Something went wrong!");
        }
        console.log(values);
      },
    });
  
    useEffect(() => {
      getAllKidsPhotos();
    }, []);
  
  
    const getAllKidsPhotos = async () => {
      setLoading(true);
      try {
        let { data } = await KidsService.fetchKidsPhotos(selectedRow.enrollment_id);
        setRowData(data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
  
    const onChangePhoto = (e) => {
      let fileMaxSize = uploadImgMaxSize(e.target.files[0],300);
      console.log(fileMaxSize);
      if(!fileMaxSize){
        setToastOpen(true);
          setToastStatus("error");
          setToastMsg("Max file size " + 300 + "KB allowed.");
          setFile("");
          document.getElementById('photoUrl').value="";
      }
      else{
       setFile(e.target.files[0]);
      }
    };
  
    const onImgDelete = async (row) => {
      let { id, photo_url } = row;
      if (photo_url) {
        let splitPublicId = photo_url?.split("/").pop();
        let publicId = splitPublicId.split(".")[0];
        console.log(publicId);
        setBackdrop(true);
        try {
          let result = await deleteImg(publicId, "kids");
          if (result) {
            const { status } = await KidsService.deleteKidPhoto(id);
            if (status) {
              setBackdrop(false);
              getAllKidsPhotos();
              setToastMsg("Kid deleted successfully");
              setToastStatus("success");
            }
          }
        } catch (err) {
          setBackdrop(false);
          setToastMsg("Someting went wrong!");
          setToastStatus("error");
        }
      }
    };
    
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-lg">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.name } Photos
            <Close onClick={onClose} sx={{ mt: 1, float:"right", cursor:"pointer"}} variant="outlined">
                  Close
                </Close>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={5} xs={12}>
                <TextField
                  fullWidth
                  type="file"
                  name="photo_url"
                  margin="dense"
                  size="small"
                  id="photoUrl"
                  onChange={onChangePhoto}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  sx={{ mr: 1, mt: 1 }}
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  Upload
                </Button>
              </Grid>
              </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
            {loading ? (
            <Loader />
          ) : (
            <>
              {rowData?.length > 0 &&
                rowData.map((data) => (
                  <Grid item xs={3}>
                    <PhotoCard onImgDelete={onImgDelete} data={data} />
                  </Grid>
                ))}
          </>)}
            </Grid>
          </Typography>
        </Box>

      </Modal>
      {toastOpen && (
        <Toast
        toastOpen={toastOpen}
        toastClose={() => setToastOpen(false)}
        toastMsg={toastMsg}
        toastStatus={toastStatus}
        />
        )}
      {backdrop && <Backdrop />}
    </div>
  );
}

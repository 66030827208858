import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

export default function DocumentCard({ data, onImgDelete }) {
  const { document_url, enrollment_id, name, title } = data;
  return (
    <>
      <Card>
        <DescriptionOutlinedIcon
          style={{ width: "100%", height: 150, color: "#fdc513" }}
        />
        <CardContent>
          <Typography
            className="text-center"
            gutterBottom
            variant="h6"
            component="div"
          >
            <div style={{ fontSize: 14, color: "gray" }}>{title}</div>
          </Typography>
        </CardContent>
        <CardActions>
          <a
          target="_blank"
            href={document_url}
            download
            size="small"
            variant="outlined"
            color="warning"
            style={{border:"1px solid", borderRadius:4, padding:'1px 9px'}}
          >
            <SimCardDownloadOutlinedIcon />
          </a>
          <Button
            style={{ minWidth: "auto", marginLeft:'auto' }}
            size="small"
            onClick={() => onImgDelete(data)}
            variant="outlined"
            color="error"
          >
            <DeleteOutlinedIcon />
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

import PersonOutlineIcon from "@mui/icons-material/Person2Rounded";
import { Chip } from "@mui/material";

const columnsData = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row?.avatar_url ? (
            <img
              className="cellImg"
              src={params.row?.avatar_url}
              alt="avatar"
            />
          ) : (
            <PersonOutlineIcon style={{ marginRight: 12, marginLeft: 4 }} />
          )}
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "enrollment_id",
    headerName: "Enrollment Id",
    minWidth: 150,
  },
  {
    field: "phone",
    headerName: "Phone",
    minWidth: 150,
  },
  {
    field: "state",
    headerName: "State",
    // flex: 1,
  },
  {
    field: "basic_amount",
    headerName: "Basic Amount",
    // flex: 1,
  },
  {
    field: "is_sponsored",
    headerName: "Sponsored",
    // flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Chip
            label={params.row.is_sponsored ? "Yes" : "No"}
            variant="outlined"
            color={params.row.is_sponsored ? "success" : "warning"}
          />
        </div>
      );
    },
  },

  {
    field: "category_name",
    headerName: "Category Name",
    flex: 0.5,
  },

  // {
  //   field: "address",
  //   headerName: "Address",
  //   flex: 1,
  // },
];

let actionHeader = (
  handleDelete,
  setOpen,
  setSelectedRow,
  setOpenExpences,
  setOpenPhotos,
  setOpenDocuments
) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              onClick={() => {
                setOpenPhotos(true);
                setSelectedRow(params.row);
              }}
              className="editButton"
            >
              Photos
            </div>
            <div
              onClick={() => {
                setOpenDocuments(true);
                setSelectedRow(params.row);
              }}
              className="editButton"
            >
              Documents
            </div>
            <div
              onClick={() => {
                setOpenExpences(true);
                setSelectedRow(params.row);
              }}
              className="editButton"
            >
              Expenses
            </div>
            <div
              onClick={() => {
                setOpen(true);
                setSelectedRow(params.row);
              }}
              className="editButton"
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return actionColumn;
};

const columnHeader = {
  columnsData,
  actionHeader,
  flex: 1,
};

export default columnHeader;

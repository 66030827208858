import * as React from "react";

import { Box, Typography, Modal, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height:"90vh",
  p: 4,
};
export default function PaymentHistoryModal({ open, onClose, rowData }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-lg">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Payment History {rowData[0]?.mentor_name}
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <table>
              <tr>
                <th>Transaction id</th>
                <th>Kid Name</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Payment Date</th>
              </tr>
              {rowData.map((d, index) => (
                <tr key={index}>
                  <td>{d.transaction_id}</td>
                  <td>{d.name}</td>
                  <td>{d.amount}</td>
                  <td>{d.status}</td>
                  <td>{d.payment_date}</td>
                </tr>
              ))}
              {}
            </table>
            <div className="text-right">
                <Button onClick={onClose} sx={{ mt: 1 }} variant="outlined">
                  Cancel
                </Button>
              </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fdc513",
    },
  },
});


ReactDOM.render(
  <React.StrictMode>
    <DarkModeContextProvider>
    <ThemeProvider theme={theme}>
      <App />
      </ThemeProvider>
    </DarkModeContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

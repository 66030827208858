import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Paper,
  Chip,
} from "@mui/material";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../../components/spinner/Spinner";
import Backdrop from "../../../components/backdrop/Backdrop";
import Toast from "../../../components/toast/Toast";
import ExpencesService from "../../../services/expences";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const eduValidationSchema = yup.object({
  education_name: yup.string("Enter education").required("Education is req"),
  amount: yup.string("Enter amt").required("Amt is req"),
});

const curValidationSchema = yup.object({
  curriculum_name: yup.string("Curriculum").required("Curriculum is req"),
  amount: yup.string("Enter amt").required("Amt is req"),
});

const extraValidationSchema = yup.object({
  extra_curriculum_name: yup
    .string("Extra curriculum")
    .required("Extra Curriculum is req."),
  amount: yup.string("Enter amt").required("Amt is req"),
});

export default function ExpenceModal({ open, onClose, isSubmitExpence, selectedRow }) {
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);

  const [eduRowData, setEduRowData] = useState([]);
  const [curRowData, setCurRowData] = useState([]);
  const [extraRowData, setExtraRowData] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [selectedExpenceRow, setSelectedExpenceRow] = useState({});

  const eduFormik = useFormik({
    initialValues: {
      kid_id: selectedRow.kid_id || "",
      education_name: selectedRow.education_name || "",
      amount: selectedRow.amount || "",
    },
    validationSchema: eduValidationSchema,
    onSubmit: async (values) => {
      isSubmitExpence(false);
        setBackdrop(true);
        try {
          values.kid_id = selectedRow.id;
          await ExpencesService.addEducationExpences(values);
          setToastOpen(true);
          setToastMsg(`Education added successfully`);
          setToastStatus("success");
          setBackdrop(false);
          getKidExpences();
          isSubmitExpence(true);
          eduFormik.resetForm();
        } catch (err) {
          console.log(err);
          setToastStatus("error");
          setToastOpen(true);
          setToastMsg("Something went wrong!");
          setBackdrop(false);
        }
    },
  });


  const curformik = useFormik({
    initialValues: {
      curriculum_name: selectedRow.curriculum_name || "",
      amount: selectedRow.amount || "",
    },
    validationSchema: curValidationSchema,
    onSubmit: async (values) => {
        isSubmitExpence(false);
        setBackdrop(true);
        try {
          values.kid_id = selectedRow.id;
          await ExpencesService.addCurriculumExpences(values);
          setToastOpen(true);
          setToastMsg(`Curriculum added successfully`);
          setToastStatus("success");
          setBackdrop(false);
          getKidExpences();
          isSubmitExpence(true);
          curformik.resetForm();
        } catch (err) {
          console.log(err);
          setToastStatus("error");
          setToastOpen(true);
          setToastMsg("Something went wrong!");
          setBackdrop(false);
        }
    }
  });

  const extraformik = useFormik({
    initialValues: {
      extra_curriculum_name: selectedRow.extra_curriculum_name || "",
      amount: selectedRow.amount || "",
    },
    validationSchema: extraValidationSchema,
    onSubmit: async (values) => {
        isSubmitExpence(false);
        setBackdrop(true);
        try {
          values.kid_id = selectedRow.id;
          await ExpencesService.addExtraCurriculumExpences(values);
          setToastOpen(true);
          setToastMsg(`Extra curriculum added successfully`);
          setToastStatus("success");
          setBackdrop(false);
          getKidExpences();
          isSubmitExpence(true);
          extraformik.resetForm();
        } catch (err) {
          console.log(err);
          setToastStatus("error");
          setToastOpen(true);
          setToastMsg("Something went wrong!");
          setBackdrop(false);
        }
    }
  });
  useEffect(() => {
    getKidExpences();
  }, []);

  let getKidExpences = async () => {
    try {
      const { data } = await ExpencesService.fetchExpencesKid(selectedRow.id);
      setEduRowData(data.data.education);
      setCurRowData(data.data.curriculum);
      setExtraRowData(data.data.extra_curriculum);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDelete = async (row,type) => {
    setBackdrop(true);
    isSubmitExpence(false);
    try {
        if(type === "Education"){
            await ExpencesService.deleteEducationExpences({id:row.id, amount:row.amount, kid_id:row.kid_id});
        }
        else if(type === "Curriculam"){
            await ExpencesService.deleteCurriculumExpences({id:row.id, amount:row.amount, kid_id:row.kid_id});
        }
        else if(type === "Extra Curriculam"){
            await ExpencesService.deleteExtraCurriculumExpences({id:row.id, amount:row.amount, kid_id:row.kid_id});
        }
      setToastOpen(true);
      setToastMsg(`${type} deleted successfully`);
      setToastStatus("success");
      setBackdrop(false);
      isSubmitExpence(true);
      getKidExpences();
    } catch (err) {
      console.log(err);
      setToastStatus("error");
      setToastOpen(true);
      setToastMsg("Something went wrong!");
      setBackdrop(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-lg">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.name} (Expenses)
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  <h4>Education Expenses</h4>

                  <form onSubmit={eduFormik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item sm={7} xs={12}>
                        <TextField
                          fullWidth
                          id="education_name"
                          name="education_name"
                          label="Education"
                          value={eduFormik.values.education_name}
                          onChange={eduFormik.handleChange}
                          onBlur={eduFormik.handleBlur}
                          margin="dense"
                          size="small"
                          error={
                            eduFormik.touched.education_name &&
                            Boolean(eduFormik.errors.education_name)
                          }
                          helperText={
                            eduFormik.touched.education_name &&
                            eduFormik.errors.education_name
                          }
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          fullWidth
                          id="amount"
                          name="amount"
                          label="Amount"
                          value={eduFormik.values.amount}
                          onChange={eduFormik.handleChange}
                          onBlur={eduFormik.handleBlur}
                          margin="dense"
                          size="small"
                          error={
                            eduFormik.touched.amount &&
                            Boolean(eduFormik.errors.amount)
                          }
                          helperText={
                            eduFormik.touched.amount && eduFormik.errors.amount
                          }
                        />
                      </Grid>
                      <Grid item xs={12} className="text-center">
                        <Button
                          color="primary"
                          sx={{ mr: 1}}
                          variant="contained"
                          type="submit"
                        >
                          Add
                        </Button>
                        <Button
                          color="primary"
                          sx={{ mr: 1}}
                          variant="outlined"
                          onClick={eduFormik.resetForm}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>

              <Grid item sm={4} xs={12}>
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  <h4>Curriculam Expenses</h4>

                  <form onSubmit={curformik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item sm={7} xs={12}>
                        <TextField
                          fullWidth
                          name="curriculum_name"
                          label="Curriculam"
                          value={curformik.values.curriculum_name}
                          onChange={curformik.handleChange}
                          onBlur={curformik.handleBlur}
                          margin="dense"
                          size="small"
                          error={
                            curformik.touched.curriculum_name &&
                            Boolean(curformik.errors.curriculum_name)
                          }
                          helperText={
                            curformik.touched.curriculum_name &&
                            curformik.errors.curriculum_name
                          }
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          fullWidth
                          id="amount"
                          name="amount"
                          label="Amount"
                          value={curformik.values.amount}
                          onChange={curformik.handleChange}
                          onBlur={curformik.handleBlur}
                          margin="dense"
                          size="small"
                          error={
                            curformik.touched.amount &&
                            Boolean(curformik.errors.amount)
                          }
                          helperText={
                            curformik.touched.amount && curformik.errors.amount
                          }
                        />
                      </Grid>
                      <Grid item xs={12} className="text-center">
                        <Button
                          color="primary"
                          sx={{ mr: 1 }}
                          variant="contained"
                          type="submit"
                        >
                          Add
                        </Button>
                        <Button
                          color="primary"
                          sx={{ mr: 1}}
                          variant="outlined"
                          onClick={curformik.resetForm}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>

              <Grid item sm={4} xs={12}>
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  <h4>Extra Curriculam Expenses</h4>

                  <form onSubmit={extraformik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item sm={7} xs={12}>
                        <TextField
                          fullWidth
                          name="extra_curriculum_name"
                          label="Extra curriculum"
                          value={extraformik.values.extra_curriculum_name}
                          onChange={extraformik.handleChange}
                          onBlur={extraformik.handleBlur}
                          margin="dense"
                          size="small"
                          error={
                            extraformik.touched.extra_curriculum_name &&
                            Boolean(extraformik.errors.extra_curriculum_name)
                          }
                          helperText={
                            extraformik.touched.extra_curriculum_name &&
                            extraformik.errors.extra_curriculum_name
                          }
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <TextField
                          fullWidth
                          id="amount"
                          name="amount"
                          label="Amount"
                          value={extraformik.values.amount}
                          onChange={extraformik.handleChange}
                          onBlur={extraformik.handleBlur}
                          margin="dense"
                          size="small"
                          error={
                            extraformik.touched.amount &&
                            Boolean(extraformik.errors.amount)
                          }
                          helperText={
                            extraformik.touched.amount &&
                            extraformik.errors.amount
                          }
                        />
                      </Grid>
                      <Grid item xs={12} className="text-center">
                        <Button
                          color="primary"
                          sx={{ mr: 1 }}
                          variant="contained"
                          type="submit"
                        >
                          Add
                        </Button>
                        <Button
                          color="primary"
                          sx={{ mr: 1}}
                          variant="outlined"
                          onClick={extraformik.resetForm}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Typography>

          {loading ? (
            <Loader />
          ) : (
            <Grid container spacing={2}>
              {eduRowData?.length > 0 && (
                <Grid item sm={4} xs={12}>
                  <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                    <h5>Education Expences</h5>
                    <hr />
                    <div style={{maxHeight:200, overflow:'auto'}}>
                      {eduRowData.map((e) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 5,
                              padding: "5px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <b
                              style={{
                                flex: 1,
                                textTransform: "capitalize",
                              }}
                            >
                              {e.education_name}
                            </b>
                            <Chip
                              label={`₹${e.amount}`}
                              variant="filled"
                              color="success"
                              size="small"
                            />
                            <DeleteForeverOutlined
                              color="error"
                              size="1rem"
                              onClick={() => handleDelete(e, "Education")}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Paper>
                </Grid>
              )}

              {curRowData?.length > 0 && (
                <Grid item sm={4} xs={12}>
                  <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                    <h5>Curriculam Expences</h5>
                    <hr />
                    <div style={{maxHeight:200, overflow:'auto'}}>
                      {curRowData.map((c) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 5,
                              padding: "5px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <b
                              style={{
                                flex: 1,
                                textTransform: "capitalize",
                              }}
                            >
                              {c.curriculum_name}
                            </b>
                            <Chip
                              label={`₹${c.amount}`}
                              variant="filled"
                              color="success"
                              size="small"
                            />
                            <DeleteForeverOutlined
                              color="error"
                              size="1rem"
                              onClick={() => handleDelete(c, "Curriculam")}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Paper>
                </Grid>
              )}

              {extraRowData?.length > 0 && (
                <Grid item sm={4} xs={12}>
                  <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                    <h5>Extra Curriculam Expences</h5>
                    <hr />
                    <div style={{maxHeight:200, overflow:'auto'}}>
                      {extraRowData.map((extra) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 5,
                              padding: "5px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <b
                              style={{
                                flex: 1,
                                textTransform: "capitalize",
                              }}
                            >
                              {extra.extra_curriculum_name}
                            </b>
                            <Chip
                              label={`₹${extra.amount}`}
                              variant="filled"
                              color="success"
                              size="small"
                            />
                            <DeleteForeverOutlined
                              color="error"
                              size="1rem"
                              onClick={() => handleDelete(extra, "Extra Curriculam")}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Paper>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Modal>
      {toastOpen && (
        <Toast
        toastOpen={toastOpen}
        toastClose={() => setToastOpen(false)}
        toastMsg={toastMsg}
        toastStatus={toastStatus}
        />
        )}
      {backdrop && <Backdrop />}
    </div>
  );
}

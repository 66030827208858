import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import ColorPicker from "react-pick-color";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  category_name: yup
    .string("Enter category name")
    .required("Category is required"),
  category_description: yup
    .string("Enter category description")
    .required("Category description is required"),
});

export default function CategoryModal({
  open,
  onClose,
  onSubmit,
  selectedRow,
}) {
  const formik = useFormik({
    initialValues: {
      category_name: selectedRow.category_name || "",
      category_description: selectedRow.category_description || "",
      category_color: selectedRow.category_color || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedRow.id) {
        values.id = selectedRow.id;
      }
      console.log(values);
      onSubmit(values);
    },
  });

  let colors = ["purple", "orange", "red", "green", "blue", "skyblue"];
  const [color, setColor] = useState("#fff");
  const [pickColor, setPickColor] = useState(false);

  // useEffect(()=>{
  //   if(formik.values.category_color){
  //     setPickColor(false);
  //   }
  // },[formik.values.category_color]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-sm">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.id ? "Update" : "Add"} Category
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="category_name"
                name="category_name"
                label="Category Name"
                value={formik.values.category_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                size="small"
                error={
                  formik.touched.category_name &&
                  Boolean(formik.errors.category_name)
                }
                helperText={
                  formik.touched.category_name && formik.errors.category_name
                }
              />

              <TextField
                fullWidth
                id="category_description"
                name="category_description"
                label="Category Description"
                value={formik.values.category_description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                rows={4}
                margin="dense"
                size="small"
                error={
                  formik.touched.category_description &&
                  Boolean(formik.errors.category_description)
                }
                helperText={
                  formik.touched.category_description &&
                  formik.errors.category_description
                }
              />

              <Grid container>
                <Grid flex={1}>
                  <FormControl fullWidth>
                    <TextField
                      name="category_color"
                      label="Category Color"
                      defaultValue=""
                      value={formik.values.category_color}
                      margin="dense"
                      size="small"
                      fullWidth
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid>
                  {pickColor && (
                    <div style={{position:'absolute', top:0, left:10, zIndex:4}}>
                      <ColorPicker
                        color={color}
                        onChange={(color) => {
                          setColor(color.hex);
                          formik.setFieldValue("category_color", color.hex);
                        }}
                      />
                    </div>
                  )}
                  <Button
                  style={{top:8, position:'relative', marginLeft:5}}
                    variant="contained"
                    onClick={() => setPickColor(!pickColor)}
                  >
                    Pick Color
                  </Button>
                </Grid>
              </Grid>

              <div className="text-right">
                <Button
                  color="primary"
                  sx={{ mr: 1, mt: 1 }}
                  variant="contained"
                  type="submit"
                >
                  {selectedRow.id ? "Update" : "Add"}
                </Button>
                <Button onClick={onClose} sx={{ mt: 1 }} variant="outlined">
                  Cancel
                </Button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

import jwt from 'jwt-decode';

const decodeToken = ()=>{
   const token =  localStorage.getItem('token');
   let userInfo = {};
   if(token && token != "undefined"){
      userInfo = jwt(token);
   }
   return userInfo
}
export default decodeToken
// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import PaymentService from "../../services/payment";
import { useEffect, useState } from "react";
import columnsHeader from "./ColumnsHeaderData";
import Loader from "../../components/spinner/Spinner";
import Backdrop from "../../components/backdrop/Backdrop";
import Toast from "../../components/toast/Toast";
import PaymentHistoryModal from "./PaymentHistoryModal";

const Payment = () => {
  const [rowData, setRowData] = useState([]);
  const [histoyMentors, setHistoyMentors] = useState([]);
  const [rowDatahistoyMentors, setRowDataHistoyMentors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDelete = async (id) => {
    setBackdrop(true);
    try {
      await PaymentService.deletePayment(id);
      setToastOpen(true);
      setToastMsg("Paymment deleted successfully");
      setToastStatus("success");
      setBackdrop(false);
      getAllPayments();
    } catch (err) {
      setToastOpen(true);
      setToastMsg("Something went wrong!");
      setToastStatus("error");
      console.log(err);
      setBackdrop(false);
    }
  };

  useEffect(() => {
    getAllPayments();
  }, []);

  const getAllPayments = async () => {
    setLoading(true);
    try {
      let { data } = await PaymentService.fetchPayments();
       let dupMentorIds = data.data.reduce((a,b)=>{
        a[b.email] = ++a[b.email] || 0
       return a;
      },{});
      setHistoyMentors(data.data.filter(p=> dupMentorIds[p.email]));
      setRowData([...new Map(data.data.map(d=> [d["email"], d])).values()]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setRowData([]);
    }
  };

  const onHistoy = (mentorEmail)=>{
    setOpen(true);
    if(mentorEmail){
      setRowDataHistoyMentors(histoyMentors.filter(s=> mentorEmail == s.email))
    }
    console.log(histoyMentors, mentorEmail)
  }
  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar}/>
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
            <Datatable
            pageTitle={"Payments"}
            columnsData={columnsHeader.columnsData}
            actionColumn={columnsHeader.actionHeader(
              handleDelete,
              onHistoy,
              histoyMentors
            )}
            rowData={rowData}
          />
        )}
      </div>
      {open && (
          <PaymentHistoryModal
            open={open}
            onClose={() => setOpen(false)}
            rowData={rowDatahistoyMentors}
          />
        )}
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop/>}
    </div>
  );
};

export default Payment;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  school_name: yup
    .string("Enter school name")
    .required("School is required"),
    school_id: yup
    .string("Enter School id")
    .required("School Id is required"),
  school_address: yup
    .string("Enter school address")
    .required("School address is required"),
});

export default function SchoolModal({ open, onClose, onSubmit, selectedRow }) {
  const formik = useFormik({
    initialValues: {
      school_name: selectedRow.school_name || "",
      school_id: selectedRow.school_id || "",
      school_address: selectedRow.school_address ||  "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(selectedRow.id){
          values.id= selectedRow.id
      }
      console.log(values)
      onSubmit(values)
    },
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-address"
      >
        <Box sx={style} className="modal-sm">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.id ? "Update" : "Add"} school
          </Typography>
          <Typography id="modal-modal-address" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="school_name"
                name="school_name"
                label="School Name"
                value={formik.values.school_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                size="small" 
                error={
                  formik.touched.school_name &&
                  Boolean(formik.errors.school_name)
                }
                helperText={
                  formik.touched.school_name && formik.errors.school_name
                }
              />
              <TextField
                fullWidth
                name="school_id"
                label="Enrolment"
                value={formik.values.school_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                size="small" 
                error={
                  formik.touched.school_id &&
                  Boolean(formik.errors.school_id)
                }
                helperText={
                  formik.touched.school_id && formik.errors.school_id
                }
              />
              <TextField
                fullWidth
                id="school_address"
                name="school_address"
                label="School address"
                value={formik.values.school_address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                rows={4}
                margin="dense"
                size="small" 
                error={
                  formik.touched.school_address &&
                  Boolean(formik.errors.school_address)
                }
                helperText={
                  formik.touched.school_address &&
                  formik.errors.school_address
                }
              />
              <div className="text-right">
              <Button color="primary" sx={{ mr: 1, mt:1 }} variant="contained" type="submit">
                {selectedRow.id ? "Update" : "Add"}
              </Button>
              <Button onClick={onClose} sx={{ mt: 1 }} variant="outlined">
                Cancel
              </Button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

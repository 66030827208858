import moment from "moment/moment";
import { Chip } from "@mui/material";

const columnsData = [
    {
      field: "name",
      headerName: "Kid Name",
      flex:1
    },
    {
      field: "full_name",
      headerName: "Montor Name",
     minWidth:100,
      renderCell: (params) => {
        return (
          <>
          {params.row.is_anonymous ? 'Anonymous': params.row.full_name}
          </>
        );
      },
    },
    {
        field: "mentor_date",
        headerName: "Mentor Date",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
                {moment(params.row.mentor_date).format("DD-MM-YYYY")}
            </div>
          );
        },
      },
      {
        field: "expire_date",
        headerName: "Expire Date",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
               {moment(params.row.expire_date).format("DD-MM-YYYY")}
            </div>
          );
        },
      },
      {
        field: "is_read",
        headerName: "Status",
        minWidth:200,
        renderCell: (params) => {
          return (
            <div>
                <Chip label={params.row.is_read ? "Read" :"Not yet Read"} variant="outlined" color={params.row.is_read ? "success" :"error"}/>
            </div>
          );
        },
      },
  ];
  
  let actionHeader = (handleDelete, setOpen, setSelectedRow) => {
    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        minWidth:100,
        renderCell: (params) => {
          return (
            <div className="cellAction">
              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row.id)}
              >
                Delete
              </div>
            </div>
          );
        },
      },
    ];
    return actionColumn;
  };
  
  const columnHeader = {
    columnsData,
    actionHeader,
  };
  
  export default columnHeader;
  
import HTTP from '../utils/HTTP';

class KidsService {
	fetchKids = () => {
		return HTTP.get('/admin/kid')
	};
	addKid = (data) => {
		return HTTP.post('/admin/kid/add', data)
	};
	updateKid = (data) => {
		return HTTP.put(`/admin/kid/update`, data)
	};
	deleteKid = (id) => {
		return HTTP.delete(`/admin/kid/delete/${id}`)
	};
//phots
	fetchKidsPhotos = (enrollment_id) => {
		return HTTP.get('/admin/kid/photo', {
			params:{
				enrollment_id,
			}
		})
	};
	addKidPhoto = (data) => {
		return HTTP.post('/admin/kid/photo/add', data)
	};
	updateKidPhoto = (data) => {
		return HTTP.put(`/admin/kid/photo/update`, data)
	};
	deleteKidPhoto = (id) => {
		return HTTP.delete(`/admin/kid/photo/delete/${id}`)
	};
	// documents
	fetchKidsDocuments = (enrollment_id) => {
		return HTTP.get('/admin/kid/document', {
			params:{
				enrollment_id,
			}
		})
	};
	addKidDocument = (data) => {
		return HTTP.post('/admin/kid/document/add', data)
	};
	updateKidDocument = (data) => {
		return HTTP.put(`/admin/kid/document/update`, data)
	};
	deleteKidDocument = (id) => {
		return HTTP.delete(`/admin/kid/document/delete/${id}`)
	};
	
}

export default new KidsService();

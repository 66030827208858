import moment from "moment/moment";

const columnsData = [
    {
      field: "name",
      headerName: "Kid Name",
      flex: 1,
    },
    {
      field: "full_name",
      headerName: "Montor Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
          {!params.row.mentor_id ? 'Anonymous': params.row.full_name}
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Mentor email",
      flex: 1,
    },
    {
      field: "mobile_no",
      headerName: "Mentor Mobile No",
      renderCell: (params) => {
          return (
            <div>
               {params.row.mobile_no ? params.row.mobile_no : params.row.anonymous_mobile_no}
            </div>
          );
        },
      flex: 1,
    },
    {
        field: "amount",
        headerName: "Amount",
        flex: 1,
      },
      {
        field: "for_months",
        headerName: "For Months",
        flex: 1,
      },
      {
        field: "category_name",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "is_active",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
               {params.row.is_active ? "Active" : "Inactive"}
            </div>
          );
        },
      },
      {
        field: "mentored_on_date",
        headerName: "Mentored Date",
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
               {moment(params.row.mentored_on_date).format("DD-MM-YYYY")}
            </div>
          );
        },
      },
  ];

  let actionHeader = (handleDelete, onHistoy, sponsorHistoy) => {
    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          minWidth:150,
          renderCell: (params) => {
            return (
              <div className="cellAction">
              
                <div
                  className="deleteButton"
                  onClick={() => handleDelete(params.row)}
                >
                  Delete
                </div>
                {sponsorHistoy.some(s=> params.row.kid_id == s.kid_id) && (
                  <div
                  className="editButton"
                  onClick={() => onHistoy(params.row.kid_id)}
                >
                  Histoy
                </div>)}
              </div>
            );
          },
        },
      ];
    return actionColumn;
  };
  
  const columnHeader = {
    columnsData,
    actionHeader,
  };
  
  export default columnHeader;
  
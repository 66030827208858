import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "@mui/material/Button";
import { toPng } from "html-to-image";
import imgUploadService from "../../services/uploadImg";
import PaymentService from "../../services/payment";
import Toast from "../../components/toast/Toast";
import Backdrop from "../../components/backdrop/Backdrop";
import moment from "moment";

const CertificateHtml = forwardRef(({ data }, ref) => {
  if (data) {
    var {
      receipt_no,
      mentor_name,
      donation_by,
      address,
      pan_number,
      transaction_id,
      amount,
      payment_date,
      kid_id,
      email,
    } = data;
  }
  const elementRef = useRef(null);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState();

  useImperativeHandle(ref, () => ({
    htmlToImageConvert() {
      setBackdrop(true);
      toPng(elementRef.current, { cacheBust: false })
        .then(async (dataUrl) => {
          setCertificateUrl(dataUrl);
          try {
            let folder = "certificates";
            const uploadResult = await imgUploadService.uploadImg({
              image: dataUrl,
              folder,
            });
            try {
              console.log(uploadResult.data);
              let paymentObj = {
                certificate_url: uploadResult.data,
                mentor_name,
                transaction_id,
                amount,
                status: donation_by,
                payment_date,
                kid_id,
                is_anonymous: false,
                email,
                receipt_no,
                address,
                pan_number,
              };
              const payment = await PaymentService.addPayments(paymentObj);
              if (payment) {
                setToastOpen(true);
                setToastMsg(
                  "Certificate created and payment added successfully"
                );
                setToastStatus("success");
                setBackdrop(false);
              }
            } catch (err) {
              setToastMsg("Something went wrong!");
              setToastStatus("error");
              setBackdrop(false);
            }
          } catch (err) {
            setToastMsg("Something went wrong!");
            setToastStatus("error");
            setBackdrop(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setBackdrop(false);
        });
    },
  }));
  const onReceiptDownload = () => {
    const link = document.createElement("a");
    link.download = "my-image-name.png";
    link.href = certificateUrl;
    link.click();
  };
  return (
    <>
      {certificateUrl && (
        <Button
          color="primary"
          sx={{ mr: 1, mt: 1 }}
          variant="contained"
          className="receipt-download"
          onClick={onReceiptDownload}
        >
          Download
        </Button>
      )}
      <div className="receipt" ref={elementRef}>
        <div className="receipt-header">
          <div className="logo">
            <img src="./img/logo.png" height={100} />
          </div>
          <div className="content">
            <h2>SHAAGIRD FOUNDATION</h2>
            <p>
              H No. 399 Prop No. 87G/F, Main 25 Ft Road, Shiv Vihar,
              <br />
              Karawal Nagar, North East Delhi 11009
            </p>
            <p>contact@shaagird.org</p>
            <p>
              <b>PAN:</b>ABJCS7197K <b style={{ marginLeft: 10 }}>CIN:</b>{" "}
              U80901DL2022NPL406795
            </p>
          </div>
        </div>
        <div className="receipt-main">
          <h1>DONATION RECEIPT</h1>
          <div style={{ position: "relative", fontStyle: "italic" }}>
            <img className="quote-left" src="./img/quoteLeft.png" />
            <p style={{ position: "relative", zIndex: 2 }}>
              We at Shaagird Foundation express gratitude for the thoughtful and
              compassionate
              <br />
              gesture on your part. This valuable contribution will greatly help
              us in restoring smiles.
            </p>
            <img className="quote-right" src="./img/quoteRight.png" />
          </div>
          <div className="form-content">
            <div className="field">
              <span>Receipt No:</span>
              <span>{receipt_no}</span>
            </div>
            <div className="field">
              <span>Received with Thanks from:</span>
              <span>{mentor_name}</span>
            </div>
            <div className="field">
              <span>Address:</span>
              <span>{address}</span>
            </div>
            <div className="field">
              <span>The Sum of Rupees:</span>
              <span>₹{amount}</span>
            </div>
            <div className="field">
              <span>For/As Donations/Grant:</span>
              <span>Donation</span>
            </div>
            <div className="field">
              <span>Through:</span>
              <span>{donation_by}</span>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div className="field">
                <span>Transaction:</span>
                <span>{transaction_id}</span>
              </div>
              <div className="field" style={{ marginLeft: 15 }}>
                <span>Date:</span>
                <span>
                  {payment_date
                    ? moment(payment_date).format("DD-MM-YYYY")
                    : ""}
                </span>
              </div>
            </div>
            <div className="field">
              <span>PAN Number:</span>
              <span>{pan_number}</span>
            </div>
          </div>
        </div>
        <div className="receipt-footer">
          <p>
            <b>Note:</b> Subject to realisation of cheque
            <br />
            Provisional Approval U/S 80G of Income Tax Act 1961 Under <br />
            Unique Registration Number <b>ABJCS7197KE20231</b>
          </p>
          <div className="signature">
            <div>
              <b>Authorised Signatory</b>
              <br />
              Shaagird Foundation
            </div>
          </div>
        </div>
      </div>

      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop />}
    </>
  );
});
export default CertificateHtml;

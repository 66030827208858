import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ChildCareOutlinedIcon from "@mui/icons-material/ChildCareOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect } from "react";
import { NotificationAddOutlined } from "@mui/icons-material";

const Sidebar = ({ isSidebar }) => {
  const { dispatch } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  let isMobile = window.matchMedia("(max-width: 767px)").matches;

  console.log(isMobile, isSidebar);

  return (
    <div
      className="sidebar"
      style={{ display: isMobile && (isSidebar ? "block" : "none") }}
    >
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo"><img width={40} style={{marginTop:5}} src="../logo.png"/></span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>

          <li>
            <DashboardIcon className="icon" />
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <span className="logo">Dashboard</span>
            </Link>
          </li>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/category" style={{ textDecoration: "none" }}>
            <li>
              <CategoryOutlinedIcon className="icon" />
              <span>Categories</span>
            </li>
          </Link>
          {/* <Link to="/school" style={{ textDecoration: "none" }}>
            <li>
              <HomeWorkOutlinedIcon className="icon" />
              <span>School</span>
            </li>
          </Link> */}
          <Link to="/kids" style={{ textDecoration: "none" }}>
            <li>
              <ChildCareOutlinedIcon className="icon" />
              <span>Kids</span>
            </li>
          </Link>

          {/* <Link to="/kids/photos" style={{ textDecoration: "none" }}>
            <li>
              <AddAPhotoOutlinedIcon className="icon" />
              <span>Photos</span>
            </li>
          </Link>
          <Link to="/kids/documents" style={{ textDecoration: "none" }}>
            <li>
              <DifferenceOutlinedIcon className="icon" />
              <span>Documents</span>
            </li>
          </Link> */}

          <p className="title">USEFUL</p>
          <Link to="/sponsered" style={{ textDecoration: "none" }}>
            <li>
              <InsertChartIcon className="icon" />
              <span>Sponsored</span>
            </li>
          </Link>
          <Link to="/notification" style={{ textDecoration: "none" }}>
            <li>
              <NotificationAddOutlined className="icon" />
              <span>Notifications</span>
            </li>
          </Link>

          <Link to="/payments" style={{ textDecoration: "none" }}>
            <li>
              <MonetizationOnOutlinedIcon className="icon" />
              <span>Payments</span>
            </li>
          </Link>

          <Link to="/certificate" style={{ textDecoration: "none" }}>
            <li>
              <CardMembershipOutlinedIcon className="icon" />
              <span>Create Certificate</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li onClick={onLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;

import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import KidService from "../../../../services/kids";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  kid_id: yup
    .string("Select Kid name")
    .required("Kid name is required"),
    extra_curriculum_name: yup
    .string("Enter Extra Curriculum")
    .required("Education is required"),
  amount: yup
    .string("Enter amount")
    .required("Amount is required"),
});

export default function EducationModal({ open, onClose, onSubmit, selectedRow }) {
  const [kids, setKids] = useState([]);
  const formik = useFormik({
    initialValues: {
      kid_id: selectedRow.kid_id || "",
      extra_curriculum_name: selectedRow.extra_curriculum_name || "",
      amount: selectedRow.amount || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedRow.id) {
        values.id = selectedRow.id;
      }
      console.log(values);
      onSubmit(values);
    },
  });

  useEffect(() => {
    getAllKids();
  }, []);

  const getAllKids = async () => {
    try {
      let { data } = await KidService.fetchKids();
      setKids(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-sm">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.id ? "Update" : "Add"} Extra Curriculum Expences
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                <TextField
                    fullWidth
                    select
                    name="kid_id"
                    label="Select Kid"
                    value={formik.values.kid_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    error={
                      formik.touched.kid_id && Boolean(formik.errors.kid_id)
                    }
                    helperText={formik.touched.kid_id && formik.errors.kid_id}
                  >
                    {kids?.length > 0 &&
                      kids.map((k) => (
                        <MenuItem value={k.id}>{k.name}</MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
              <TextField
                fullWidth
                id="extra_curriculum_name"
                name="extra_curriculum_name"
                label="Extra Curriculum"
                value={formik.values.extra_curriculum_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                size="small"
                error={
                  formik.touched.extra_curriculum_name &&
                  Boolean(formik.errors.extra_curriculum_name)
                }
                helperText={
                  formik.touched.extra_curriculum_name &&
                  formik.errors.extra_curriculum_name
                }
              />

              <TextField
                fullWidth
                id="amount"
                name="amount"
                label="Amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                size="small"
                error={
                  formik.touched.amount &&
                  Boolean(formik.errors.amount)
                }
                helperText={
                  formik.touched.amount &&
                  formik.errors.amount
                }
              />
              <div className="text-right">
                <Button
                  color="primary"
                  sx={{ mr: 1, mt: 1 }}
                  variant="contained"
                  type="submit"
                >
                  {selectedRow.id ? "Update" : "Add"}
                </Button>
                <Button onClick={onClose} sx={{ mt: 1 }} variant="outlined">
                  Cancel
                </Button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import CategoiresService from "../../services/categories";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CategoryModal from "./components/CategoryModal";
import Toast from "../../components/toast/Toast";
import columnsHeader from "./ColumnHeaderData";
import Loader from "../../components/spinner/Spinner";
import Backdrop from "../../components/backdrop/Backdrop";

const Category = () => {
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);

  const handleDelete = async (id) => {
    setBackdrop(true);
    try {
      await CategoiresService.deleteCategory(id);
      setToastOpen(true);
      setToastMsg("Category deleted successfully");
      setToastStatus("success");
      setOpen(false);
      getAllCategories();
      setBackdrop(false);
    } catch (err) {
      setBackdrop(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    setLoading(true);
    try {
      let { data } = await CategoiresService.fetchCategories();
      setRowData(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setBackdrop(true);
    try {
      if (values.id) {
        await CategoiresService.updateCategory(values);
      } else {
        await CategoiresService.addCategory(values);
      }
      setToastOpen(true);
      setToastMsg(
        `Category ${values?.id ? "updated" : "created"} successfully`
      );
      setToastStatus("success");
      setOpen(false);
      getAllCategories();
      setSelectedRow({});
      setBackdrop(false);

    } catch (err) {
      console.log(err);
      setToastOpen(true);
      setBackdrop(false);
      setToastStatus("error");
      setToastMsg("Something went wrong!");
    }
  };

  return (
    <div className="list">
     <Sidebar isSidebar={isSidebar}/>
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
          <Datatable
            pageTitle={"Categories"}
            columnsData={columnsHeader.columnsData}
            actionColumn={columnsHeader.actionHeader(
              handleDelete,
              setOpen,
              setSelectedRow
            )}
            rowData={rowData}
            addBtnTxt={"Add Category"}
            onAddBtn={() => {
              setOpen(true);
              setSelectedRow({});
            }}
          />
        )}

        {open && (
          <CategoryModal
            onSubmit={onSubmit}
            selectedRow={selectedRow}
            open={open}
            onClose={() => setOpen(false)}
          />
        )}
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop/>}
    </div>
  );
};

export default Category;

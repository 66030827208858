import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormHelperText,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, MenuItem, Select } from "@mui/material";
import CategoiresService from "../../../services/categories";
import PersonOutlineIcon from "@mui/icons-material/Person2Rounded";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import convertBase64 from "../../../utils/convertBase64";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  bosmhadow: 24,
  p: 4,
};

const modalStyle1 = {
  position: "absolute",
  top: "10%",
  left: "10%",
  overflow: "scroll",
  height: "100%",
  display: "block",
};

const validationSchema = yup.object({
  name: yup.string("Enter Name").required("Name is required"),
  gender: yup.string("Select Gender").required("Gender is required"),
  category_id: yup.string("Select Category").required("Category is required"),
  phone: yup.string("Enter Phone").required("Phone is required"),
  date_of_birth: yup
    .string("Enter Date of birth")
    .required("Date of birth is required"),
  basic_amount: yup
    .string("Enter Basic Expence")
    .required("Basic expence is required"),
  enrollment_id: yup
    .string("Enter Basic Enrollment Id")
    .required("Enrollment Id is required"),
  state: yup.string("Enter State").required("State is required"),
});

export default function KidModal({
  open,
  onClose,
  onSubmit,
  selectedRow,
  setFile,
  onDeleteAvtar,
  setCoverFile,
}) {
  const [categories, setCatgories] = useState([]);
  const [avtarImg, setAvtarImg] = useState();

  const formik = useFormik({
    initialValues: {
      name: selectedRow.name || "",
      school_id: selectedRow.school_id || "",
      enrollment_id: selectedRow.enrollment_id || "",
      gender: selectedRow.gender || "",
      avatar_url: selectedRow.avatar_url || "",
      cover_url: selectedRow.cover_url || "",
      father_name: selectedRow.father_name || "",
      mother_name: selectedRow.mother_name || "",
      bio: selectedRow.bio || "",
      phone: selectedRow.phone || "",
      address: selectedRow.address || "",
      category_id: selectedRow.category_id || "",
      date_of_birth: selectedRow.date_of_birth || "",
      state: selectedRow.state || "",
      basic_amount: selectedRow.basic_amount || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(selectedRow.total_expenses){
        var remainTotalExpence =  +selectedRow.total_expenses - +selectedRow.basic_amount
      }
      values.total_expenses = selectedRow.total_expenses
        ? parseInt(remainTotalExpence) + parseInt(values.basic_amount)
        : parseInt(values.basic_amount);
      if (selectedRow.id) {
        values.id = selectedRow.id;
      }
      console.log(values);
      onSubmit(values, avtarImg);
    },
  });

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      let { data } = await CategoiresService.fetchCategories();
      setCatgories(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onImgChange = async (file) => {
    if (file) {
      let base64Url = await convertBase64(file);
      setAvtarImg("");
      setAvtarImg(base64Url);
      setFile(file);
      console.log(base64Url, "avtar");
    }
  };

  const onCoverImg = async (coverFile) => {
    if (coverFile) {
      let base64Url = await convertBase64(coverFile);
      setCoverFile(coverFile);
      console.log(base64Url, "cover");
    }
  };

  const onDeleteAvtarHandler = (row, cover) => {
    setFile("");
    setAvtarImg("");
    onDeleteAvtar(row, cover);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={modalStyle1}
      >
        <Box sx={style} className="modal-lg">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRow.id ? "Update" : "Add"} Kids
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item sm={3} xs={12}>
                  <div className="profile-img">
                    <input
                      style={{ display: "none" }}
                      id="avtarImgId"
                      type="file"
                      onChange={(e) => onImgChange(e.target.files[0])}
                    />
                    {avtarImg || selectedRow.avatar_url ? (
                      <img src={avtarImg || selectedRow.avatar_url} />
                    ) : (
                      <PersonOutlineIcon />
                    )}
                  </div>
                  {selectedRow.avatar_url ? (
                    <label
                      className="upload-btn"
                      onClick={() => onDeleteAvtarHandler(selectedRow)}
                      variant="outlined"
                      fullWidth
                    >
                      Delete Avtar
                    </label>
                  ) : (
                    <label
                      className="upload-btn"
                      htmlFor="avtarImgId"
                      onClick={() => setFile(avtarImg)}
                      variant="outlined"
                      fullWidth
                    >
                      Choose Avtar
                    </label>
                  )}
                </Grid>
                <Grid item sm={9} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        name="school_id"
                        label="School Name"
                        defaultValue=""
                        value={formik.values.school_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                      ></TextField>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="enrollment_id"
                        name="enrollment_id"
                        label="Enrollment Id"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.enrollment_id}
                        margin="dense"
                        error={
                          formik.touched.enrollment_id &&
                          Boolean(formik.errors.enrollment_id)
                        }
                        helperText={
                          formik.touched.enrollment_id &&
                          formik.errors.enrollment_id
                        }
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          name="gender"
                          label="Select Gender"
                          defaultValue=""
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={
                            formik.touched.gender &&
                            Boolean(formik.errors.gender)
                          }
                          helperText={
                            formik.touched.gender && formik.errors.gender
                          }
                        >
                          <MenuItem value={""}>Select Gender</MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          name="category_id"
                          label="Select Category"
                          defaultValue=""
                          value={formik.values.category_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={
                            formik.touched.category_id &&
                            Boolean(formik.errors.category_id)
                          }
                          helperText={
                            formik.touched.category_id &&
                            formik.errors.category_id
                          }
                        >
                          {categories?.length > 0 &&
                            categories.map((c) => (
                              <MenuItem value={c.id}>
                                {c.category_name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        size="small"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="date_of_birth"
                        name="date_of_birth"
                        label="Date of Birth"
                        size="small"
                        value={formik.values.date_of_birth}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={
                          formik.touched.date_of_birth &&
                          Boolean(formik.errors.date_of_birth)
                        }
                        helperText={
                          formik.touched.date_of_birth &&
                          formik.errors.date_of_birth
                        }
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        name="basic_amount"
                        label="Basic Amount"
                        size="small"
                        value={formik.values.basic_amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={
                          formik.touched.basic_amount &&
                          Boolean(formik.errors.basic_amount)
                        }
                        helperText={
                          formik.touched.basic_amount &&
                          formik.errors.basic_amount
                        }
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        name="state"
                        label="State"
                        size="small"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormControl style={{ width: "100%" }}>
                        {selectedRow.cover_url ? (
                          <div
                            style={{
                              border: "1px solid #c4c4c4",
                              width: "100%",
                              position: "relative",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                maxHeight: 40,
                                objectFit: "cover",
                              }}
                              src={selectedRow.cover_url}
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-5px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                onDeleteAvtarHandler(selectedRow, "cover")
                              }
                            >
                              <CloseOutlined />
                            </span>
                          </div>
                        ) : (
                          <TextField
                            fullWidth
                            id="coverPhoto"
                            size="small"
                            type="file"
                            onChange={(e) => onCoverImg(e.target.files[0])}
                            margin="dense"
                            helperText="Cover photo"
                          />
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="father_name"
                        name="father_name"
                        label="Father Name"
                        value={formik.values.father_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="mother_name"
                        name="mother_name"
                        label="Mother Name"
                        value={formik.values.mother_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="bio"
                        name="bio"
                        label="bio"
                        size="small"
                        multiline
                        rows={2}
                        value={formik.values.bio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.bio && Boolean(formik.errors.bio)}
                        helperText={formik.touched.bio && formik.errors.bio}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        label="Address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        multiline
                        rows={2}
                        margin="dense"
                        size="small"
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="text-right">
                <Button
                  color="primary"
                  sx={{ mr: 1, mt: 1 }}
                  variant="contained"
                  type="submit"
                >
                  {selectedRow.id ? "Update" : "Add"}
                </Button>
                <Button onClick={onClose} sx={{ mt: 1 }} variant="outlined">
                  Cancel
                </Button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

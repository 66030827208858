// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import KidsService from "../../services/kids";
import { uploadImg, deleteImg } from "../../utils/uploadImg";
import { useEffect, useState } from "react";
import Toast from "../../components/toast/Toast";
import KidModal from "./components/KidModal";
import columnsHeader from "./ColumnHeaderData";
import Loader from "../../components/spinner/Spinner";
import Backdrop from "../../components/backdrop/Backdrop";
import ExpenceModal from "./components/ExpencesModal";
import PhotosModal from "./components/PhotosModal";
import DocumentsModal from "./components/DocumentsModal";

const Kids = () => {
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPhotos, setOpenPhotos] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [isSubmitExpence, setIsSubmitExpence] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [coverFile, setCoverFile] = useState();
  const [backdrop, setBackdrop] = useState(false);
  const [openExpences, setOpenExpences] = useState(false);

  const [isSidebar, setIsSidebar] = useState(null);

  const handleDelete = async (id) => {
    setBackdrop(true);
    try {
      await KidsService.deleteKid(id);
      setToastOpen(true);
      setToastMsg("Kid deleted successfully");
      setToastStatus("success");
      setOpen(false);
      setBackdrop(false);
      getAllKids();
    } catch (err) {
      console.log(err);
      setToastStatus("error");
      setToastOpen(true);
      if (err.response.data == "This already in used unable to delete") {
        setToastMsg(err.response.data);
      } else {
        setToastMsg("Something went wrong!");
      }
      setBackdrop(false);
    }
  };

  useEffect(() => {
    getAllKids();
  }, []);

  useEffect(() => {
    if (isSubmitExpence) {
      getAllKids();
    }
  }, [isSubmitExpence]);

  const getAllKids = async () => {
    setLoading(true);
    setRowData([]);
    try {
      let { data } = await KidsService.fetchKids();
      setRowData(data.data);
      setLoading(false);
    } catch (err) {
      setRowData([]);
      console.log(err);
      setLoading(false);
    }
  };

  const onSubmit = async (values, avtarImg) => {
    setBackdrop(true);
    try {
      if (coverFile) {
        const url = await uploadImg(coverFile, "kids");
        console.log(url, "url");
        values.cover_url = url;
      }
      if (avtarImg) {
        const url = await uploadImg(file, "kids");
        console.log(url, "url");
        values.avatar_url = url;
      }

      if (values.id) {
        await KidsService.updateKid(values);
      } else {
        await KidsService.addKid(values);
      }
      setToastOpen(true);
      setToastMsg(`Kid ${values?.id ? "updated" : "created"} successfully`);
      setToastStatus("success");
      setOpen(false);
      getAllKids();
      setSelectedRow({});
      setBackdrop(false);
    } catch (err) {
      console.log(err);
      setToastOpen(true);
      setToastStatus("error");
      setBackdrop(false);
      setToastMsg("Something went wrong!");
    }
  };

  const onImgDelete = async (row, cover) => {
    let { avatar_url, cover_url } = row;
    if (avatar_url || cover_url) {
      if (cover) {
        var splitPublicId = cover_url?.split("/").pop();
      } else {
        var splitPublicId = avatar_url?.split("/").pop();
      }
      let publicId = splitPublicId.split(".")[0];
      console.log(publicId);
      setBackdrop(true);
      try {
        let result = await deleteImg(publicId, "kids");
        if (result) {
          if (cover) {
            row.cover_url = "";
          } else {
            row.avatar_url = "";
          }
          const { status } = await KidsService.updateKid(row);
          if (status) {
            setBackdrop(false);
            getAllKids();
            setToastMsg("Kid photo deleted successfully");
            setToastStatus("success");
          }
        }
      } catch (err) {
        setBackdrop(false);
        setToastMsg("Someting went wrong!");
        setToastStatus("error");
      }
    }
  };

  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar} />
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
          <Datatable
            pageTitle={"Kids"}
            columnsData={columnsHeader.columnsData}
            actionColumn={columnsHeader.actionHeader(
              handleDelete,
              setOpen,
              setSelectedRow,
              setOpenExpences,
              setOpenPhotos,
              setOpenDocuments
            )}
            rowData={rowData}
            addBtnTxt={"Add Kids"}
            onAddBtn={() => {
              setOpen(true);
              setSelectedRow({});
            }}
          />
        )}
        {open && (
          <KidModal
            onSubmit={onSubmit}
            selectedRow={selectedRow}
            open={open}
            onClose={() => setOpen(false)}
            setFile={setFile}
            setCoverFile={setCoverFile}
            onDeleteAvtar={onImgDelete}
          />
        )}
      </div>

      {openExpences && (
        <ExpenceModal
          selectedRow={selectedRow}
          isSubmitExpence={setIsSubmitExpence}
          open={openExpences}
          onClose={() => setOpenExpences(false)}
        />
      )}
      {openPhotos && (
        <PhotosModal
          selectedRow={selectedRow}
          open={openPhotos}
          onClose={() => setOpenPhotos(false)}
        />
      )}

      {openDocuments && (
        <DocumentsModal
          selectedRow={selectedRow}
          open={openDocuments}
          onClose={() => setOpenDocuments(false)}
        />
      )}

      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop />}
    </div>
  );
};

export default Kids;

import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function PhotoCard({data, onImgDelete}) {
  const {photo_url, name, enrollment_id} = data;
  return (
    <>
    <Card>
      <CardMedia
        sx={{ height: 200 }}
        image={photo_url}
      />
      <CardActions>
        <Button size="small" onClick={()=>onImgDelete(data)} variant='outlined' fullWidth color='error'>Delete</Button>
      </CardActions>
    </Card>
    </>
  );
}
import axios from "axios";
// import { getCookie, removeCookie } from './cookie';
// import { toast } from "react-toastify";
const HTTP = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

HTTP.defaults.timeout = 30000;
HTTP.defaults.timeoutErrorMessage = "timeout";

HTTP.interceptors.request.use(
  (request) => {
    let token = localStorage.getItem("token");
    if (!request.url.includes("login")) {
      request.headers["Authorization"] = `Bearer ${token}`;
      //request.headers['WebURL'] = window.location.pathname.slice(1);
    } else {
      request.headers["token"] = token;
    }
    return request;
  },
  (error) => {
    console.log(error, "erro");
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle response errors (401)
    //let navigate = useNavigate();
    if (
      error.response.status === 401 ||
      error.response.data.message === "401 Unauthorized"
    ) {
      //toast.error(error?.response?.headers?.message || "Session expired!");
      // removeCookie();
      setTimeout(() => {
        // navigate("/");
      }, 1000);
    }
    return Promise.reject(error);
  }
);

export default HTTP;

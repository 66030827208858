import React, { useState } from "react";
import "./login.scss";
import { TextField, Button, Radio, RadioGroup, FormControl, FormControlLabel  } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import OTPInput, { ResendOTP } from "otp-input-react";
import UserService from "../../services/user";
import Toast from "../../components/toast/Toast";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  email: yup.string("Enter email").email().required("Email is required"),
  password: yup.string("Enter password").required("Password is required"),
});

function Login() {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [user, setUser] = useState("admin");

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        if(user === "admin"){
          var { data } = await UserService.adminLogin(values);
        }
        else {
          var { data } = await UserService.superAdminLogin(values);
        }
        if (data.token) {
          setToastOpen(true);
          setToastMsg("Login successfully");
          setToastStatus("success");
          localStorage.setItem("token", data.token);
          navigate("/dashboard");
        }
      } catch (err) {
        setToastOpen(true);
        setToastMsg("Please check your credential!");
        setToastStatus("error");
      }
    },
  });


  return (
    <div className={`loginContainer`}>
      <div className="forms-container">
        <div className="signin-signup">
          <div className="sign-in-form loginForm">
            <img className="logo-m" src="../logo.png"/>
            <h2 className="title">Sign in</h2>
            <div className="social-media">
              <form onSubmit={formik.handleSubmit}>
              <FormControl>
              <RadioGroup
                defaultValue="admin"
                name="radio-buttons-group"
                onChange={(e)=> setUser(e.target.value)}
                style={{flexDirection:"row"}}
              >
                <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                <FormControlLabel value="superAdmin" control={<Radio />} label="Super Admin" />
              </RadioGroup>
            </FormControl>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  size="small"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  fullWidth
                  type="password"
                  id="password"
                  name="password"
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  size="small"
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <div className="text-right">
                  <Button
                    fullWidth
                    color="primary"
                    sx={{ mr: 1, mt: 1 }}
                    variant="contained"
                    type="submit"
                    className="btn"
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
      </div>
      
       
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
    </div>
  );
}

export default Login;

import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";

export default function Toast({
  toastClose,
  toastMsg,
  toastOpen,
  toastStatus,
}) {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={toastClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={toastOpen}
        autoHideDuration={2000}
        onClose={toastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      >

        <Alert severity={toastStatus}>{toastMsg}</Alert>
      </Snackbar>
    </div>
  );
}

import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import { useFormik } from "formik";
import * as yup from "yup";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import KidService from "../../services/kids";
import CertificateHtml from "./certifcateHtml";

const validationSchema = yup.object({
  mentor_name: yup.string().required("Doner Name is required"),
  email: yup.string().email().required("Doner Email is required"),
  amount: yup.number().required("Amount is required"),
  // kid_id: yup.string().required("Kid name is required"),
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  justifyContent: "center",
  margin: "25px 0",
  color: theme.palette.text.secondary,
}));

export default function Certificate() {
  const [isSidebar, setIsSidebar] = useState(null);
  const [kids, setKids] = useState([]);
  const [certifcateData, setCertifcateData] = useState(null);

  const childRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      receipt_no: "",
      mentor_name: "",
      email: "",
      donation_by: "Cash",
      address: "",
      pan_number: "",
      transaction_id: "",
      payment_date: new Date().toLocaleDateString(),
      amount: "",
      kid_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.receipt_no = Math.floor(100000000 + Math.random() * 900000000);
      console.log(values);
      setCertifcateData(values);
      childRef.current.htmlToImageConvert();
      formik.resetForm();
    },
  });
  useEffect(() => {
    getAllKids();
  }, []);

  const getAllKids = async () => {
    try {
      let { data } = await KidService.fetchKids();
      setKids(data.data.filter((k) => k.is_sponsored === false));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (formik.values.kid_id) {
      let kid = kids.find((k) => k.id == formik.values.kid_id);
      formik.setFieldValue("amount", kid.total_expenses);
    }
  }, [formik.values.kid_id]);
  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar} />
      <div className="listContainer" style={{ padding: "0 15px" }}>
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Item>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Create 80G Certificate
                </Typography>
                <Typography id="modal-modal-address" sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    id="mentor_name"
                    name="mentor_name"
                    label="Enter Donor Name"
                    value={formik.values.mentor_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    error={
                      formik.touched.mentor_name &&
                      Boolean(formik.errors.mentor_name)
                    }
                    helperText={
                      formik.touched.mentor_name && formik.errors.mentor_name
                    }
                  />
                  <TextField
                    fullWidth
                    name="email"
                    label="Enter Doner Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    fullWidth
                    select
                    name="kid_id"
                    label="Select Kid"
                    value={formik.values.kid_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    error={
                      formik.touched.kid_id && Boolean(formik.errors.kid_id)
                    }
                    helperText={formik.touched.kid_id && formik.errors.kid_id}
                  >
                    {kids?.length > 0 &&
                      kids.map((k) => (
                        <MenuItem value={k.id}>{k.name}</MenuItem>
                      ))}
                  </TextField>

                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Donation By
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Cash"
                      name="donation_by"
                      label="Donation By"
                      value={formik.values.donation_by}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="Cash"
                        control={<Radio />}
                        label="Cash"
                      />
                      <FormControlLabel
                        value="Cheque"
                        control={<Radio />}
                        label="Cheque"
                      />
                      <FormControlLabel
                        value="Draft"
                        control={<Radio />}
                        label="Draft"
                      />
                      <FormControlLabel
                        value="Online Transfer"
                        control={<Radio />}
                        label="Online Transfer"
                      />
                      <FormControlLabel
                        value="Account Transfer"
                        control={<Radio />}
                        label="Account Transfer"
                      />
                    </RadioGroup>
                  </FormControl>

                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    multiline
                    rows={3}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                  <TextField
                    fullWidth
                    id="pan_number"
                    name="pan_number"
                    label="PAN Number"
                    value={formik.values.pan_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                  />
                  <TextField
                    fullWidth
                    id="transaction_id"
                    name="transaction_id"
                    label="Transcation Id"
                    value={formik.values.transaction_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                  />
                  <TextField
                    fullWidth
                    id="payment_date"
                    name="payment_date"
                    label="Date of Donation"
                    value={formik.values.payment_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    type="date"
                  />

                  <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    label="Requested For Amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    size="small"
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />

                  <div className="text-right">
                    <Button
                      color="primary"
                      sx={{ mr: 1, mt: 1 }}
                      variant="contained"
                      onClick={formik.handleSubmit}
                    >
                      Create
                    </Button>
                    <Button
                      onClick={() => formik.resetForm()}
                      sx={{ mt: 1 }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={7}>
            <Item>
              <Box style={{ position: "relative" }}>
                <Typography variant="h6" component="h2">
                  Certificate Preview
                </Typography>
                <CertificateHtml ref={childRef} data={certifcateData} />
              </Box>
            </Item>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

// import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import KidsService from "../../services/kids";
import { uploadImg, deleteImg, uploadImgMaxSize } from "../../utils/uploadImg";
import { useEffect, useState } from "react";
import Toast from "../../components/toast/Toast";
import Loader from "../../components/spinner/Spinner";
import PhotoCard from "./components/PhotoCard";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Button,
  Paper,
  Autocomplete,
} from "@mui/material";

import Backdrop from "../../components/backdrop/Backdrop";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  enrollment_id: yup
    .string("Select Enrollment Id")
    .required("Enrollment Id is required"),
});

const KidsPhtos = () => {
  const [rowData, setRowData] = useState([]);
  const [kids, setKids] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);

  const formik = useFormik({
    initialValues: {
      enrollment_id: "",
      photo_url: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setBackdrop(true);
      try {
        if (file){
        const url = await uploadImg(file, "kids");
        console.log(url, "url");
        values.photo_url = url;
        if (values.id && url) {
          await KidsService.updateKidPhoto(values);
        } else if(url) {
          await KidsService.addKidPhoto(values);
        }
        setToastOpen(true);
        setToastMsg(
          `Kid Photo ${values?.id ? "updated" : "added"} successfully`
        );
        setToastStatus("success");
        setOpen(false);
        getAllKidsPhotos();
        setSelectedRow({});
        setBackdrop(false);
        setFile("");
        document.getElementById("photoUrl").value = "";
        formik.resetForm();
      }
      } catch (err) {
        console.log(err);
        setBackdrop(false);
        setToastOpen(true);
        setToastStatus("error");
        setToastMsg("Something went wrong!");
      }
      console.log(values);
    },
  });

  useEffect(() => {
    getAllKids();
    getAllKidsPhotos();
  }, []);

  const getAllKids = async () => {
    setLoading(true);
    try {
      let { data } = await KidsService.fetchKids();
      setKids(
        data.data.map((k) => {
          return {
            label: k.enrollment_id,
          };
        })
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getAllKidsPhotos = async () => {
    setLoading(true);
    try {
      let { data } = await KidsService.fetchKidsPhotos();
      setRowData(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onChangePhoto = (e) => {
    let fileMaxSize = uploadImgMaxSize(e.target.files[0],300);
    console.log(fileMaxSize);
    if(!fileMaxSize){
      setToastOpen(true);
        setToastStatus("error");
        setToastMsg("Max file size " + 300 + "KB allowed.");
        setFile("");
        document.getElementById('photoUrl').value="";
    }
    else{
     setFile(e.target.files[0]);
    }
  };

  const onImgDelete = async (row) => {
    let { id, photo_url } = row;
    if (photo_url) {
      let splitPublicId = photo_url?.split("/").pop();
      let publicId = splitPublicId.split(".")[0];
      console.log(publicId);
      setBackdrop(true);
      try {
        let result = await deleteImg(publicId, "kids");
        if (result) {
          const { status } = await KidsService.deleteKidPhoto(id);
          if (status) {
            setBackdrop(false);
            getAllKidsPhotos();
            setToastMsg("Kid deleted successfully");
            setToastStatus("success");
          }
        }
      } catch (err) {
        setBackdrop(false);
        setToastMsg("Someting went wrong!");
        setToastStatus("error");
      }
    }
  };
  useEffect(() => {
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    close.addEventListener("click", () => {
      formik.setFieldValue("enrollment_id", "")
    });
  }, [formik.values.enrollment_id]);

  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar} />
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        <Grid spacing={2} style={{ padding: 20 }}>
          <Typography variant="h5" color="gray">
            Kids Photos
          </Typography>
          <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ marginTop: 1 }}
                    disablePortal
                    id="combo-box-demo"
                    options={kids}
                    fullWidth
                    margin="dense"
                    size="small"
                    value={formik.values.enrollment_id}
                    defaultValue={formik.values.enrollment_id}
                    onChange={(event, value) =>
                      formik.setFieldValue("enrollment_id", value.label)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.enrollment_id &&
                          Boolean(formik.errors.enrollment_id)
                        }
                        helperText={
                          formik.touched.enrollment_id &&
                          formik.errors.enrollment_id
                        }
                        onBlur={formik.handleBlur}
                        label="Select Enrollment Id"
                        name="enrollment_id"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={5} xs={12}>
                <TextField
                  fullWidth
                  type="file"
                  name="photo_url"
                  margin="dense"
                  size="small"
                  id="photoUrl"
                  onChange={onChangePhoto}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  sx={{ mr: 1, mt: 1 }}
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {loading ? (
            <Loader />
          ) : (
            <Grid container spacing={2} style={{ marginTop: 20, maxHeight:300, overflowY:"auto" }}>
              {rowData?.length > 0 &&
                rowData.map((data) => (
                  <Grid item xs={3}>
                    <PhotoCard onImgDelete={onImgDelete} data={data} />
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop />}
    </div>
  );
};

export default KidsPhtos;

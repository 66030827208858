import HTTP from "../utils/HTTP";

class UserService {
  fetchSuperAdmin = () => {
    return HTTP.get("/user/super-admin");
  };
  fetchAdmin = () => {
    return HTTP.get("/user");
  };
  fetchMentor = () => {
    return HTTP.get("/user/mentor");
  };
  adminLogin = (data) => {
    return HTTP.post("/user/login/admin", { ...data });
  };
  superAdminLogin = (data) => {
    return HTTP.post("/user/login/super-admin", { ...data });
  };
  loginUser = (data) => {
    return HTTP.post("/user/login", { ...data });
  };
  register = (data) => {
    return HTTP.post("/user/register", { ...data });
  };
  createCertificate = (data) => {
    return HTTP.post("/user/create-certificate", { ...data });
  };
  updateUser = (data) => {
    return HTTP.put("/user/update", { ...data });
  };
  deleteUser = ({ id, role }) => {
    return HTTP.delete(`/user/delete`, {
      params: {
        id,
        role,
      },
    });
  };
}

export default new UserService();

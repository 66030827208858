const columnsData = [
  {
    field: "category_name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "category_description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "category_color",
    headerName: "Color",
    flex: 1,
  },
  
];

let actionHeader = (handleDelete, setOpen, setSelectedRow) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth:150,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              onClick={() => {
                setOpen(true);
                setSelectedRow(params.row);
              }}
              className="editButton"
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return actionColumn;
};

const columnHeader = {
  columnsData,
  actionHeader,
};

export default columnHeader;

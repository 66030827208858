import "./navbar.scss";
import { DarkModeContext } from "../../context/darkModeContext";
import Notification from "../notification/Notification";
import { useContext } from "react";
import decodeToken from "../../utils/decodeToken";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useState } from "react";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import { useNavigate } from "react-router-dom";

const Navbar = ({ setIsSidebar, isSidebar }) => {
  const { dispatch } = useContext(DarkModeContext);
  const { full_name } = decodeToken();

  const navigate = useNavigate();
  const handleNotification = () => {
    alert("Hello");
    <Notification />;
  };

  const onLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  return (
    <div className="navbar">
      <div className="wrapper">
      <span className="logo-m"><img width={35} style={{marginTop:5}} src="../logo.png"/></span>
        <div className="items ml-auto">
          <div className="item">
            <PersonOutlinedIcon />
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {full_name}
              <ExpandMoreOutlinedIcon />
            </Button>
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
          <Button onClick={onToggleSidebar} className="toggle-btn" variant="outlined">
            {isSidebar ? (
              <CloseOutlined />
            ) : (
              <MenuOutlinedIcon/>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// import "./list.scss"
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import ExpencesService from "../../../services/expences";
import { useEffect, useState } from "react";
import ExtraCurriculumModal from "./components/ExtraCurriculamModal";
import Toast from "../../../components/toast/Toast";
import columnsHeader from "./ColumnsHeaderData";
import Loader from "../../../components/spinner/Spinner";
import Backdrop from "../../../components/backdrop/Backdrop";

const ExtraCurriculumExpences = () => {
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);

  const handleDelete = async (id) => {
    setBackdrop(true);
    try {
      await ExpencesService.deleteExtraCurriculumExpences(id);
      setToastOpen(true);
      setToastMsg("ExtraCurriculum deleted successfully");
      setToastStatus("success");
      setOpen(false);
      setBackdrop(false);
      getAllExtraCurriculumExpences();
    } catch (err) {
      setBackdrop(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getAllExtraCurriculumExpences();
  }, []);

  const getAllExtraCurriculumExpences = async () => {
    setLoading(true);
    try {
      let { data } = await ExpencesService.fetchExtraCurriculumExpences();
      setRowData(data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSubmit = async (values) => {
    setBackdrop(true);
    try {
      if (values.id) {
        await ExpencesService.updateExtraCurriculumExpences(values);
      } else {
        await ExpencesService.addExtraCurriculumExpences(values);
      }
      setToastOpen(true);
      setToastMsg(
        `Extra Curriculum ${values?.id ? "updated" : "created"} successfully`
      );
      setToastStatus("success");
      setOpen(false);
      getAllExtraCurriculumExpences();
      setSelectedRow({});
      setBackdrop(false);
    } catch (err) {
      setBackdrop(false);
      console.log(err);
      setToastOpen(true);
      setToastStatus("error");
      setToastMsg("Something went wrong!");
    }
  };

  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar}/>
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
        <Datatable
          pageTitle={"Extra Curriculum Expences"}
          columnsData={columnsHeader.columnsData}
          actionColumn={columnsHeader.actionHeader(
            handleDelete,
            setOpen,
            setSelectedRow
          )}
          rowData={rowData}
          addBtnTxt={"Add Extra Curriculum Expences"}
          onAddBtn={() => {
            setOpen(true);
            setSelectedRow({});
          }}
        />)}
        
        {open && (
          <ExtraCurriculumModal
            onSubmit={onSubmit}
            selectedRow={selectedRow}
            open={open}
            onClose={() => setOpen(false)}
          />
        )}
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {backdrop && <Backdrop/>}
    </div>
  );
};

export default ExtraCurriculumExpences;
